import React from 'react';
import {Container, Col, Row} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';

import './ImgBullet.scss';

export default function ImgBullet(props) {
  return (
    <div className={classNames(`imgBullet imgBullet-border-${props.borderColor}`)}>
      <Row className="d-flex align-items-center">
        <Col xs="12" md="4" lg="3" className="imgBullet-img-col mb-4 mb-md-0 text-center">
          <LazyLoad height={180} offset={100}>
            <img className="imgBullet-img rounded img-fluid" src={props.img} />
          </LazyLoad>
        </Col>
        <Col xs="12" md="8" lg="9" className="imgBullet-copy">
          {props.children}
        </Col>
      </Row>
    </div>
  );
}

ImgBullet.defaultProps = {
  borderColor: 'black-25'
};

export function ImgBulletCard({
  img,
  bgColor = 'white',
  textColor = 'black',
  children,
  imgColLg = '3',
  textColLg = '9',
  ...props
}) {
  return (
    <Row className="d-flex justify-content-center">
      <Col xs="12" sm="10" md="8" lg="12">
        <div
          className={classNames(
            `imgBulletCard background-${bgColor} text-${textColor} box-shadow-black-50 rounded mb-4`
          )}
        >
          <Row className="d-flex align-items-center m-0">
            <Col xs="12" lg={imgColLg} className="imgBulletCard-img-col px-0">
              <LazyLoad height={180} offset={100}>
                <img className="imgBulletCard-img img-fluid" src={img} />
              </LazyLoad>
            </Col>
            <Col xs="12" lg={textColLg} className="text-center text-lg-left py-3 pl-lg-4">
              {children}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export function ImgCard({
  img,
  bgColor = 'white',
  textColor = 'black',
  children,
  imgColLg = '3',
  textColLg = '9',
  ...props
}) {
  let imageStyle = {
    backgroundImage: `url('${img}')`
  };
  return (
    <Row className="d-flex justify-content-center">
      <Col xs="12" sm="10" md="8" lg="12">
        <div
          className={classNames(
            `imgBulletCard background-${bgColor} text-${textColor} box-shadow-black-50 rounded mb-4 d-flex flex-column`
          )}
        >
          <Row className="d-flex align-items-stretch m-0 w-100">
            <Col
              xs="12"
              lg={imgColLg}
              className="image-card-col background-cover background-image-center px-0"
              style={imageStyle}
            />
            <Col
              xs="12"
              lg={textColLg}
              className="image-card-content-col text-center text-lg-left py-4 pl-lg-4 d-flex flex-column"
            >
              <div className="image-card-content my-auto">{children}</div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
