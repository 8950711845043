import React, {useCallback, useState} from 'react';
import LazyLoad from 'react-lazyload';
import {Button, Container, Col, Row} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import {testimonials} from 'data/testimonials.json';
import './Testimonial.scss';

// Testimonial Box
function TestimonialItem({id, text, cite, image, backgroundColor, textColor, ...props}) {
  return (
    <div
      className={classNames(
        `testimonial-box rounded background-${backgroundColor} text-${textColor} d-flex flex-column h-100`
      )}
    >
      <LazyLoad height={245} offset={100}>
        <img className="testimonial-img img-fluid rounded-top" src={image} />
      </LazyLoad>
      <div className="testimonial-content d-flex flex-column justify-content-between h-100 p-3">
        <div className="testimonial-copy">&ldquo;{text}&rdquo;</div>
        <div className="testimonial-cite">
          <hr />
          <div className="testimonial-name text-small text-700 m-0">
            <ReactMarkdown source={cite} />
          </div>
        </div>
      </div>
    </div>
  );
}

TestimonialItem.defaultProps = {
  backgroundColor: 'white',
  textColor: 'black'
};

export default function Testimonial({id, ...props}) {
  const testimonial = testimonials.find((e) => e.id === id);
  return testimonial ? <TestimonialItem {...testimonial} {...props} /> : null;
}

// Testimonial with Circle Image
function TestimonialCircleItem({id, text, cite, image, textColor, ...props}) {
  let imgOrder = `order-lg-${props.imgPosition === 'right' ? '2' : '1'}`;
  let textOrder = `order-lg-${props.imgPosition === 'right' ? '1' : '2'}`;

  return (
    <div className={classNames(`testimonial-circle text-${textColor}`, props.className)}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="10" md="8" lg="3" className={classNames(`text-center mb-4 mb-lg-0 ${imgOrder}`)}>
          <img className="testimonial-img speaker-headshot img-fluid rounded-circle" src={image} />
        </Col>
        <Col xs="12" lg="9" className={classNames(`${textOrder}`)}>
          <div className="testimonial-content d-flex flex-column justify-content-between">
            <div className="testimonial-copy">
              <ReactMarkdown source={text} />
            </div>
            <div className="testimonial-cite">
              <div className="testimonial-name">
                <ReactMarkdown source={cite} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

TestimonialCircleItem.defaultProps = {
  textColor: 'black'
};

export function TestimonialCircle({id, ...props}) {
  const testimonial = testimonials.find((e) => e.id === id);
  return testimonial ? <TestimonialCircleItem {...testimonial} {...props} /> : null;
}

// Testimonial Card Item
function TestimonialCardItem({id, text, cite, image, textColor, ...props}) {
  return (
    <div className={classNames(`testimonial-card ${image ? null : 'testimonial-card-text'}`, props.className)}>
      <div className="testimonial-card-inner">
        {image ? (
          <div className="testimonial-card-image mx-auto">
            <img className="testimonial-card-img img-fluid rounded-circle" src={image} />
          </div>
        ) : null}
        <div className="testimonial-card-content">
          <Row className="d-flex align-items-center justify-content-center">
            <Col xs="12">
              <div className="d-flex flex-column justify-content-between">
                <div className="testimonial-card-copy">
                  <ReactMarkdown source={text} />
                </div>
                <div className="testimonial-card-cite">
                  <ReactMarkdown source={cite} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export function TestimonialCard({id, ...props}) {
  const testimonial = testimonials.find((e) => e.id === id);
  return testimonial ? <TestimonialCardItem {...testimonial} {...props} /> : null;
}

// Testimonial No Image
function TestimonialTextItem({id, text, cite, image, textColor = 'black', ...props}) {
  return (
    <div className={classNames(`testimonial-text text-${textColor}`, props.className)}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="12">
          <div className="testimonial-content d-flex flex-column justify-content-between  text-center">
            <ReactMarkdown className="testimonial-copy" source={text} />
            <div className="testimonial-cite">
              <div className="testimonial-name">
                <ReactMarkdown source={cite} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function TestimonialText({id, ...props}) {
  const testimonial = testimonials.find((e) => e.id === id);
  return testimonial ? <TestimonialTextItem {...testimonial} {...props} /> : null;
}

// Testimonial Bubble
function TestimonialBubbleItem({
  id,
  text,
  cite,
  image,
  textColor = 'black',
  bgColor = 'light-gray',
  textAlign = 'center',
  ...props
}) {
  return (
    <div className={classNames(`testimonial-bubble text-${textColor}`, props.className)}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="12">
          <div className={classNames(`testimonial-content`)}>
            <ReactMarkdown
              className={classNames(`testimonial-copy testimonial-bubble-${bgColor} text-${textColor}`)}
              source={text}
            />
            <div className="testimonial-cite">
              <div className="testimonial-name">
                <ReactMarkdown source={cite} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function TestimonialBubble({id, ...props}) {
  const testimonial = testimonials.find((e) => e.id === id);
  return testimonial ? <TestimonialBubbleItem {...testimonial} {...props} /> : null;
}
