import React, {Component, useCallback, useState} from 'react';
import {Row, Col, Collapse} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp} from '@fortawesome/pro-light-svg-icons';
import {modules} from 'data/module.json';
import IconList, {IconListItem} from 'components/Elements/IconList';
import classNames from 'classnames';
import './ModuleAccordion.scss';

const remarkSubSuper = require('remark-sub-super');

export default function ModuleAccordion({list = modules, ...props}) {
  const [activeCards, setActiveCards] = React.useState([0]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return list.map((module, index) => {
    return (
      <div className="module-item" key={index}>
        <div className="module-item-header d-flex align-items-center justify-content-between" onClick={onClick(index)}>
          <div className="module-item-header-text">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" md="3" lg="2" className="text-center mb-3 mb-md-0 pl-lg-0">
                <img className="module-img" src={module.thumb} />
              </Col>
              <Col xs="12" md="9" lg="10">
                <div className="module-item-count">{module.module}</div>
                <div className="module-item-title">{module.name}</div>
              </Col>
            </Row>
          </div>
          <div className="module-item-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row">
            <FontAwesomeIcon
              className="module-item-icon mb-1 mb-lg-0 mr-lg-1"
              icon={activeCards.includes(index) ? faChevronCircleUp : faChevronCircleDown}
            />
            <div className="module-item-header-icon-text text-small text-uppercase">
              {activeCards.includes(index) ? (
                <>Close</>
              ) : (
                <>
                  <span className="d-none d-lg-inline">Learn</span> More
                </>
              )}
            </div>
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="module-item-content">
            <ReactMarkdown
              className="module-item-description"
              source={module.description || 'description'}
              plugins={[remarkSubSuper]}
              renderers={{
                sub: 'sub',
                sup: 'sup'
              }}
            />
            <IconList>
              {modules[index].list.map((list) => (
                <IconListItem>
                  <ReactMarkdown className="module-item-lessons" source={list || 'list-item'} />
                </IconListItem>
              ))}
            </IconList>
            <Row className="align-items-center justify-content-center justify-content-sm-start text-center">
              <Col xs="12" md="auto" className="mb-4 mb-md-0">
                <img
                  className="rounded-circle img-border-shadow"
                  src={module.expertImage}
                  style={{maxHeight: '120px'}}
                />
              </Col>
              <Col xs="12" md="auto" className="text-md-left">
                <h5 className="section-heading mb-2">
                  Expert Interview With <span className="text-400">{module.expertName}</span>
                </h5>
                <p className="mb-0">
                  <em>{module.expertInterview}</em>
                </p>
              </Col>
            </Row>
          </div>
        </Collapse>
      </div>
    );
  });
}

export function ModuleList({list = modules, ...props}) {
  const [activeCards, setActiveCards] = React.useState([0]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return list.map((module, index) => {
    return (
      <div className="module-item" key={index}>
        <div className="module-item-heading d-flex align-items-center justify-content-between">
          <div className="module-item-header-text">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" className="px-3 px-lg-0">
                <div className="module-item-count">{module.module}</div>
                <div className="module-item-title">
                  Day {module.day}: {module.name}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="module-item-content px-3 px-lg-0">
          <ReactMarkdown
            className="module-item-description"
            source={module.description || 'description'}
            plugins={[remarkSubSuper]}
            renderers={{
              sub: 'sub',
              sup: 'sup'
            }}
          />
          {!module.recipe ? null : (
            <>
              {module.recipe2 ? (
                <>
                  <Row className="align-items-center justify-content-center justify-content-sm-start mt-3 mt-md-0">
                    <Col xs="12" md="9" lg="10" className="order-md-2 mb-3 mb-md-0">
                      <span className="text-800 text-uppercase">Daily Recipe</span>
                      <br />
                      {module.recipe}
                    </Col>
                    <Col xs="12" md="3" lg="2" className="text-center order-md-1">
                      <img className="rounded border" src={module.recipeImage} />
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center justify-content-sm-start mt-3">
                    <Col xs="12" md="9" lg="10" className="order-md-2 mb-3 mb-md-0">
                      <span className="text-800 text-uppercase">Daily Recipe</span>
                      <br />
                      {module.recipe2}
                    </Col>
                    <Col xs="12" md="3" lg="2" className="text-center order-md-1">
                      <img className="rounded border" src={module.recipe2Image} />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="align-items-center justify-content-center justify-content-sm-start mt-3 mt-md-0">
                    <Col xs="12" md="9" lg="10" className="order-md-2 mb-3 mb-md-0">
                      <span className="text-800 text-uppercase">Daily Recipe</span>
                      <br />
                      {module.recipe}
                    </Col>
                    <Col xs="12" md="3" lg="2" className="text-center order-md-1">
                      <img className="rounded border" src={module.recipeImage} />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  });
}
