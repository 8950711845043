import React from 'react';
import {Head} from 'react-static';

export default function PageMeta({
  url = 'https://accelerator.foodrevolution.org/join-special/',
  ogImage = 'https://cdn.foodrevolution.org/fda/og/fda-202112-open-graph-v1-1200x1200px.png',
  title = 'Get the 14-Day Plant-Powered Accelerator Today!',
  description = 'If you want to accelerate your healthy eating journey, this course is for you. Get 14 days of research-backed and action-inspiring lessons and recipes that will set you up for plant-powered success.'
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@afoodrevolution" />
      <meta property="twitter:image" content={ogImage} />
      <meta property="fb:app_id" content="1685199371775570" />
      <meta property="og:site_name" content="14-Day Plant-Powered Accelerator" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
    </Head>
  );
}
