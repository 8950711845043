import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {usePhaseState} from 'funnel-schedule/phase-context';
import queryString from 'query-string';
import {useUserState} from 'contexts/user';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import useBeacon from 'utils/useBeacon';
import PageMeta from 'components/PageMeta';
import Section, {SectionImage} from 'components/Elements/Section';
import HeaderVideo from 'components/Elements/HeaderVideo';
import CheckoutButton from 'components/CheckoutButton';
import {SalesDisclaimer} from 'components/SalesCta';
import {ImgCard} from 'components/Elements/ImgBullet';
import {TestimonialBubble, TestimonialCircle} from 'components/Testimonial';
import Guarantee from 'components/Guarantee';
import CountdownTimer from 'components/Elements/CountdownTimer';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {RecipeTileBox} from 'components/Elements/Tile';
import ShareButtons from 'components/Social/ShareButtons';
import {faChevronCircleRight, faLock, faTimes} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Footer from 'components/Elements/Footer';
import {recipeTileWlc} from 'data/recipe.json';
import GuaranteeBox from 'components/Guarantee';
import Video from 'components/Video';

export default function ({
  noExitIntent = false,
  noFixedVideo = false,
  pageTitle = 'Join WHOLE Life Club',
  pageDescription = 'Join and get instant access to the entire collection of WHOLE Life Club recipes, registered dieticians and certified health coaches, a warm and welcoming community that wants you to succeed, and so much MORE.',
  shareUrl = 'https://wlc.foodrevolution.org/whole/',
  shareImg = 'https://cdn.foodrevolution.org/wlc/opengraph/wlc-2021-open-graph-sales-v1-1200x630.jpg',
  facebookDescription = 'No matter how much you know...it’s what you do that matters most. Join WHOLE Life Club, and take action for your health today and your WHOLE life. Registration opens twice a year – stay tuned!',
  twitterDescription = 'No matter how much you know...it’s what you do that matters most. Join WHOLE Life Club, and take action for your health today and your #WHOLELife. Registration opens twice a year – stay tuned!',
  pinterestDescription = 'No matter how much you know...it’s what you do that matters most. Join WHOLE Life Club, and take action for your health today and your WHOLE life. Registration opens twice a year – stay tuned!',
  pinterestImage = 'https://cdn.foodrevolution.org/wlc/opengraph/wlc-2021-open-graph-sales-v1-1000x1500.jpg',
  ...props
}) {
  const qs = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
  const {t} = qs.t ? qs : {};

  function SpecialTimer() {
    return <>{t ? <CountdownTimer time={t * 1000 + 1468800000} /> : null}</>;
  }

  function CtaSection({
    title = (
      <>
        <Row className="text-center text-white mb-4">
          <Col>
            <p>
              <b>
                Special opportunity for 14-Day Plant-Powered <br className="d-none d-sm-block d-lg-none" />
                Accelerator participants ONLY.
              </b>
              {t ? (
                <>
                  {' '}
                  <br className="d-none d-xl-block" />
                  Your chance to join this amazing community for 48% off ends in:
                </>
              ) : null}
            </p>
            <SpecialTimer />
          </Col>
        </Row>
      </>
    ),
    ...props
  }) {
    return (
      <Section id={props.id} color="gradient-indigo-purple-right" {...props}>
        <Container>
          {title ? title : null}

          <Row className="d-flex justify-content-center">
            <Col lg="10" xl="8">
              <div className="purchase-card">
                <div className="purchase-card-header background-black text-white rounded-top p-3 p-lg-4">
                  <h3 className="purchase-card-title text-uppercase text-center m-0">Get Started Now!</h3>
                </div>
                <div className="purchase-card-content background-white rounded-bottom px-4 px-lg-5 py-4">
                  <div className="text-center mb-4">
                    <h4 className="section-heading">
                      Normally <del>$247</del>
                    </h4>
                    <h3 className="text-green m-0">
                      Join now for $19/month, or save <br className="d-none d-xl-block" />
                      bigger with a yearly membership of $127 <br className="d-none d-xl-block" />
                      (which comes to just $10.58/month)!
                    </h3>
                  </div>
                  <div className="list-simple-check-wrap">
                    <IconList className="text-base">
                      <IconListItem>5 delicious, whole foods, plant-powered recipes every single week.</IconListItem>
                      <IconListItem>Weekly videos from Ocean Robbins, delivered to your inbox.</IconListItem>
                      <IconListItem>Monthly Action Hour events with health revolutionaries.</IconListItem>
                      <IconListItem>Action Checklists to keep you on track.</IconListItem>
                      <IconListItem>100s of fabulous recipes, with new options added all the time.</IconListItem>
                      <IconListItem>A private, moderated community.</IconListItem>
                      <IconListItem>Access to Health Coaches & Certified Nutritionists.</IconListItem>
                      <IconListItem>Access to the entire library of 100+ videos.</IconListItem>
                      <IconListItem>Annual rates that will never go up as long as you remain a member.</IconListItem>
                      <IconListItem>A state-of-the-art membership site that’s easy to navigate.</IconListItem>
                      <IconListItem>500+ health articles, and much more!</IconListItem>
                    </IconList>
                  </div>
                  <div className="text-center my-4">
                    <p className="m-0">
                      <i>This is not just a club. It’s a liferaft!</i>
                    </p>
                  </div>
                  <div className="text-center">
                    <CheckoutButton className="offer-box-button" sku="wlc" price="127" slug="wlc-fda-alumni" />
                    <SalesDisclaimer />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }

  useBeacon();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImg} />

      <div className="page-content">
        <>
          <Section id="whole-header" className="section-angle-first" color="black" innerClass="text-white text-center">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading">
                    How To Prevent and Even Reverse Today’s Most Deadly Diseases — From Cancer to Heart Disease,
                    Dementia, and More — By Up to 90%, <i>Even</i> If You Have a Family History
                  </h2>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col lg="10" className="video-col px-0 px-sm-3">
                  <div className="px-3 px-sm-0">
                    <h5 className="section-heading text-uppercase mt-3">
                      Watch This Important Message From Ocean Robbins:
                    </h5>
                  </div>
                  <Video url="https://www.youtube.com/watch?v=aP06T9t0vuI" label="FDA - WLC Sales" />
                </Col>
              </Row>
            </Container>
          </Section>

          <CtaSection id="whole-cta-1" />

          <Section id="whole-wonder" color="black" innerClass="text-white text-center">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading">
                    Wondering how people <br className="d-none d-md-block d-lg-none" />
                    in their 50s, 60s, <br className="d-none d-xl-block" />
                    and even 90s stay energetic and youthful?
                  </h2>
                  <h3 className="m-0">
                    <em>
                      Are they just lucky? <br className="d-none d-sm-block d-md-none" />
                      What’s their secret?
                    </em>
                  </h3>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-fact" color="light-gray">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10">
                  <h2 className="section-heading text-uppercase">It’s a Fact:</h2>
                  <p>The most cutting-edge scientific research shows that when you eat the right foods...</p>
                  <IconList>
                    <IconListItem>
                      It’s possible to slash your risk of cancer, heart disease, Alzheimer’s, type 2 diabetes,
                      osteoporosis, obesity, rheumatoid arthritis, and other health challenges.
                    </IconListItem>
                    <IconListItem>
                      Lethargy, sickness and brain fog <i>don’t</i> have to be the norm.
                    </IconListItem>
                    <IconListItem>
                      By eating certain foods, you can enjoy deep sleep and skyrocketing energy, and be amazed as food
                      cravings vanish.
                    </IconListItem>
                    <IconListItem>
                      You’ll also improve the planet, thanks to fewer pesticides and factory farms eroding soil and
                      polluting the environment.
                    </IconListItem>
                  </IconList>
                  <h5 className="text-400">
                    <i>But no matter how much you know...</i>
                  </h5>
                  <h4 className="mt-0">
                    It’s <i>what you DO</i> that matters most.
                  </h4>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-testimonials-1" color="white" angle="bottom">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-only-heading text-center">Is WHOLE Life Club worth it?</h2>
                  <h3 className="mt-3 text-center">Our members say, “YES!”</h3>
                  <p className="text-center text-16 mb-0">
                    <i>
                      After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered
                      dietary pattern. <br className="d-none d-xl-block" />
                      Their testimonials reflect their unique experience. Your specific results will vary.
                    </i>
                  </p>
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-heidi-h" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-tina-g" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-maureen" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-alexa-p" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-barbara" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-nerina" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-kathy-w" />
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-eating" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center mb-5">
                    When it comes to eating <br className="d-none d-lg-block d-xl-none" />
                    for energy, <br className="d-none d-xl-block" />
                    minimal health issues, and reducing current symptoms…
                  </h2>
                </Col>
              </Row>
              <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/eating-keep-up.jpg')}>
                <h4 className="section-heading">There’s a lot to keep up with.</h4>
                <p>“Where will I get my protein?”</p>
                <p>“I’m eating right, but I’m still overweight. What am I doing wrong?”</p>
                <p>
                  “Will I become iron-deficient <br className="d-none d-sm-block d-lg-none" />
                  if I go plant-based?”
                </p>
              </ImgCard>
              <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/eating-information.jpg')}>
                <h4 className="section-heading">
                  New information <br className="d-none d-sm-block d-lg-none" />
                  comes out every day.
                </h4>
                <p>
                  “Hmm… this article says tofu is good, but I’ve also heard <br className="d-none d-xl-block" />
                  it increases estrogen.”
                </p>
                <p>“What about calcium? I’m concerned about osteoporosis and weak bones.”</p>
              </ImgCard>
              <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/eating-on-board.jpg')}>
                <h4 className="section-heading">
                  Your family might <br className="d-none d-md-block d-lg-none" />
                  not be 100% on board, <br className="d-none d-md-block d-lg-none d-xl-block" />
                  and you might hear things like:
                </h4>
                <p>“Black bean burgers? Again?”</p>
                <p>“Can’t we just get take out?”</p>
                <p>“C’mon, one bite won’t hurt you.”</p>
              </ImgCard>
              <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/eating-better.jpg')}>
                <h4 className="section-heading">
                  You <i>want</i> to do better, but may find yourself saying...
                </h4>
                <p>
                  “I can’t seem to get over the craving for cookies / potato chips / my{' '}
                  <br className="d-none d-xl-block" />
                  night time bowl of ice cream.”
                </p>
                <p>
                  “Veggies aren’t as tasty as pizza. Can I really learn <br className="d-none d-xl-block" />
                  to love them?”
                </p>
                <p>
                  “How do I find time to cook when <br className="d-none d-sm-block d-xl-none" />
                  I’m already busy?”
                </p>
              </ImgCard>
            </Container>
          </Section>

          <SectionImage
            id="whole-track"
            color="white"
            imgPosition="right"
            imgUrl={require('static/wlc/woman-carrot-thinking-square.jpg')}
          >
            <h2 className="section-heading">
              Even when you know what <br className="d-none d-sm-block d-md-none" />
              you “should” do...
            </h2>
            <h4 className="mt-0">
              How do you stay inspired and <br className="d-none d-lg-block d-xl-none" />
              on track with:
            </h4>
            <IconList iconColor="purple">
              <IconListItem icon={faChevronCircleRight}>
                Cooking plant-based meals and keeping them interesting?
              </IconListItem>
              <IconListItem icon={faChevronCircleRight}>
                Staying on top of the most critical health topics?
              </IconListItem>
              <IconListItem icon={faChevronCircleRight}>
                Eating in a way that supports your values and how you want to be in the world?
              </IconListItem>
            </IconList>
            <h4 className="mt-0">
              How do you go from simply <i>wanting</i> to eat healthier, to enjoying foods that give you a long, vibrant
              life?
            </h4>
          </SectionImage>

          <Section id="whole-testimonial-2" color="black" innerClass="text-white">
            <Container>
              <Row>
                <Col>
                  <TestimonialCircle id="wlc-juanita" className="text-white mb-5" />
                  <p className="text-center text-16 mb-0">
                    <i>
                      After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered
                      dietary pattern. <br className="d-none d-xl-block" />
                      Their testimonials reflect their unique experience. Your specific results will vary.
                    </i>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-recipes" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center mb-5">
                    Stay On Track & In Love <br className="d-none d-md-block d-xl-none" />
                    With Plant-Based Recipes
                  </h2>
                </Col>
              </Row>
              <Row>
                {recipeTileWlc.map((recipeTile) => (
                  <Col lg="6" className="mb-4">
                    <RecipeTileBox id={recipeTile.id} key={recipeTile.id} recipeTileWlc />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <h3 className="text-center">...and many, many more!</h3>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-intro" color="white">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10">
                  <h4 className="section-heading text-center">Introducing...</h4>
                  <h2 className="section-heading text-center">WHOLE Life Club</h2>
                  <h4 className="mt-0 text-400 text-center">
                    WHOLE: <strong className="text-underline">W</strong>elcoming{' '}
                    <strong className="text-underline">H</strong>ealthy <br className="d-none d-sm-block d-xl-none" />
                    <strong className="text-underline">O</strong>rganic <strong className="text-underline">L</strong>
                    ifestyle <strong className="text-underline">E</strong>mpowerment
                  </h4>
                  <h5 className="mt-0 text-400 text-center">
                    <i>Motivation & Inspiration for your Healthiest Life</i>
                  </h5>
                  <Row className="d-flex justify-content-center">
                    <Col xs="12" lg="8">
                      <img
                        className="img-fluid"
                        src="https://cdn.foodrevolution.org/wlc/wlc-laptop-mock.png"
                        width="1256"
                        height="752"
                        alt="WHOLE Life Club graphic"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="text-400 text-center mt-5">
                    Goodbye, bad habits. <br />
                    <strong>Hello, good ones.</strong>
                  </h2>
                  <h2 className="text-400 text-center mt-0">
                    Goodbye, confusion. <br />
                    <strong>Hello, clarity.</strong>
                  </h2>
                  <h2 className="text-400 text-center mt-0">
                    Goodbye, swimming upstream. <br />
                    <strong>Hello, wind at your back.</strong>
                  </h2>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-testimonial-2" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center mb-3">What do members have to say?</h2>
                  <p className="text-center text-16 mb-5">
                    <i>
                      After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered
                      dietary pattern. <br className="d-none d-xl-block" />
                      Their testimonials reflect their unique experience. Your specific results will vary.
                    </i>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TestimonialBubble id="wlc-kathy-w-short" bgColor="white" className="mb-5" />
                  <TestimonialBubble id="wlc-robin-short" bgColor="white" />
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-more" color="green" innerClass="text-white">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10">
                  <h2 className="section-only-heading text-center">
                    WHOLE Life Club is <br className="d-none d-sm-block" />
                    <i>so much more</i> than just food
                  </h2>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-more-2" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-uppercase text-center">
                    Weekly Videos <br className="d-none d-sm-block d-lg-none" />
                    From Ocean Robbins
                  </h2>
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-center">
                <Col sm="10" lg="8" className="mb-4">
                  <img className="img-border rounded" src={require('static/wlc/action-of-the-week.jpg')} />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col lg="10">
                  <p>
                    No one is born with an instruction manual on how to eat and what to do — but when you join WHOLE
                    Life Club, you can be sure you won’t miss out on the most important and life-changing information.
                  </p>
                  <p>
                    These Weekly Guides are like getting a private coaching session with Ocean each week, where he
                    shares the truth on MUST-KNOW topics that can transform your life.
                  </p>
                  <p>
                    You’ll get weekly dispatches on topics like healthy versus unhealthy fats, adrenals,
                    immune-supporting herbs, adaptogens, fitness myths, cancer-kicking foods, vitamins and minerals,
                    practical tips like how to say “no” and stay positive, and so much more.
                  </p>
                  <p>
                    With Ocean by your side, you’ll stay vibrant and motivated and keep taking action to be your best
                    YOU.
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-more-2" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-uppercase text-center">Curated (Delicious) Recipes</h2>
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-center">
                <Col sm="10" lg="8" className="mb-4">
                  <img src={require('static/wlc/recipe.png')} />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col lg="10">
                  <p>
                    No more getting stuck in food ruts, with the same meals over and over. Each week, you’ll get new
                    recipes that dovetail perfectly with Ocean’s weekly guidance.
                  </p>
                  <p>
                    Discover superstars of nutrition with exciting recipes that showcase new ways to delight your taste
                    buds (and your family, too)!
                  </p>
                  <p>
                    For example, when you learn about zinc and how it has been found to shorten colds, you’ll get
                    zinc-rich recipes like Ginger Miso Dressing, Creamy Lentil Spinach Dahl, and Chocolate Almond
                    Mousse.
                  </p>
                  <p>
                    Or when the topic is avoiding anemia, you can enjoy adding more healthy non-heme iron to your plate
                    with recipes like Orange-Miso-Glazed Tofu and Chocolate Raspberry Smoothie.
                  </p>
                  <p>
                    Each recipe is a super-healthy crowd pleaser — always vegan, with gluten-free, soy-free, and
                    salt-free options — and mega-delicious, so everyone can partake.
                  </p>
                  <p>
                    The instant you join, you’ll have the entire collection of WHOLE Life Club recipes at your
                    fingertips.
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-more-3" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-uppercase text-center">
                    Monthly Action Hours <br className="d-none d-sm-block d-xl-none" />
                    with Leading Experts
                  </h2>
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-center">
                <Col sm="10" lg="8" className="mb-4">
                  <img src={require('static/wlc/action-hour.png')} />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col lg="10">
                  <p>
                    Would you like front-row seats to the latest scientific discoveries from doctors who are{' '}
                    <i>New York Times</i> bestselling authors and world-famous health experts?
                  </p>
                  <p>As a WHOLE Life Club member, you’ll be treated to monthly Action Hours with health heroes like:</p>
                  <ul>
                    <li>Daniel Amen, MD</li>
                    <li>Julieanna Hever, RD</li>
                    <li>Dean Ornish, MD</li>
                    <li>Justin Michael Williams</li>
                    <li>Susan Pierce Thompson, PhD</li>
                    <li>Michael Greger, MD</li>
                    <li>Pedram Shojai, OMD</li>
                  </ul>
                  <p>
                    ...and other top wellness specialists who can’t wait to share their decades of research and wisdom
                    with you.
                  </p>
                  <p>
                    Get the scoop on topics such as longer lifespans, eating to defeat cancer, ending food addiction,
                    conquering autoimmune disease, healthy hormones, calming anxiety naturally, habits to avoid heart
                    disease, and more.
                  </p>
                  <p>
                    As a member, you’ll have the opportunity to ask the featured experts questions in advance, so they
                    can address them during their sessions.
                  </p>
                  <p>
                    Plus, the instant you join the WHOLE Life Club, you’ll have access to the entire library of 30+
                    Action Hours.
                  </p>
                  <p>
                    You’ll get the recorded audio to download each month, plus handouts, implementation checklists, and
                    articles so you can learn more. If you prefer to read, you’ll love getting the written transcript.
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-more-4" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-uppercase text-center">
                    Access to Registered Dieticians and <br className="d-none d-xl-block" />
                    Certified <br className="d-none d-md-block d-lg-none" />
                    Health Coaches
                  </h2>
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-center">
                <Col sm="10" lg="8" className="mb-4">
                  <img src={require('static/wlc/coaches.png')} />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col lg="10">
                  <p>
                    Get answers to your most pressing health and nutrition questions — <i>finally!</i>
                  </p>
                  <p>Wondering which foods are good for heart health?</p>
                  <p>Unclear about the difference between (and optimal ratio for) omega-3s and omega-6s?</p>
                  <p>
                    Have a loved one with a disturbing diagnosis, and wonder what the studies say about how food can
                    help?
                  </p>
                  <p>
                    If you’ve ever tried to find answers on YouTube or random blogs, then you know how frustrating it
                    can be to read ten articles that all seem to contradict each other – and if you’re leery of placing
                    your health in the hands of the peanut gallery in Facebook groups, or you can never seem to squeeze
                    in all your questions with your doctor, we understand.
                  </p>
                  <p>
                    That’s why we created a way for you to get ALL your questions answered by our experienced Community
                    Support team, which includes registered dietitians and certified health coaches. Our team has
                    encountered just about everything, and will give you feedback and insights based on the latest
                    studies and research.
                  </p>
                  <p>
                    Want to dig deeper? Our team can direct you to one of our 500+ articles, so you can get the best
                    insights about just about any health issue that pops up.
                  </p>
                  <p>This kind of help can be a true lifesaver. Our team is ready to offer you exceptional support.</p>
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage id="whole-more-5" color="white" imgUrl={require('static/wlc/img-community.jpg')}>
            <h2 className="section-heading text-uppercase">
              Community <br className="d-none d-xl-block" />
              (Uplifting Support)
            </h2>
            <p>
              No naysayers here! We know it can be tough to stick to goals if you’re on your own. You deserve a safe
              space where you are supported by others who are on the same journey.
            </p>
            <p>
              In a world where the status quo revolves around toxic food and encourages us to put up with feeling worse
              each year, we need to surround ourselves with others who share our values of healthy food, delicious
              recipes, and making a better planet for future generations.
            </p>
            <p>
              In this warm and welcoming group, you’ll find encouragement and a space to ask questions and cheer each
              other on as we celebrate wins — and the truth is we celebrate often.
            </p>
            <p>You simply have to experience for yourself what a difference this kind of support will make!</p>
          </SectionImage>

          <SectionImage
            id="whole-more-6"
            color="light-gray"
            imgPosition="right"
            imgUrl={require('static/wlc/science-square.jpg')}
            imgUrlMobile={require('static/wlc/science.jpg')}
          >
            <h2 className="section-heading text-uppercase">Cutting-Edge Science</h2>
            <p>
              We’re fortunate to live in the most scientifically advanced period in the history of the world. However,
              information overload can make it hard to find accurate, credible sources for the topics you care about.
            </p>
            <p>
              Getting the right information makes all the difference when it comes to topics like cancer, autoimmune
              disorders, heart disease, thyroid health, weight and metabolism, pain, Alzheimer’s, and more.
            </p>
            <p>
              New discoveries are made daily — and you can count on us to keep you up to date with breaking news from
              credible sources.
            </p>
          </SectionImage>

          <SectionImage id="whole-theme" color="white" imgUrl={require('static/wlc/woman-research-square.jpg')}>
            <h2 className="section-heading">Get the latest research on:</h2>
            <IconList>
              <IconListItem>Immunity</IconListItem>
              <IconListItem>Disease prevention</IconListItem>
              <IconListItem>Detoxing: using food to heal your body naturally</IconListItem>
              <IconListItem>Weight loss and food freedom</IconListItem>
              <IconListItem>Anti-cancer Living</IconListItem>
              <IconListItem>Brain health</IconListItem>
              <IconListItem>Mental health</IconListItem>
              <IconListItem>Hormonal balance</IconListItem>
              <IconListItem>Foods to fight cancer</IconListItem>
              <IconListItem>Heart health: unlocking the 4 pillars of a healthy heart</IconListItem>
              <IconListItem>Pesticides and food safety: protecting yourself from toxic food</IconListItem>
              <IconListItem>Much, much more</IconListItem>
            </IconList>
          </SectionImage>

          <Section id="whole-testimonial-3" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center mb-3">What do members have to say?</h2>
                  <p className="text-center text-16 mb-5">
                    <i>
                      After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered
                      dietary pattern. <br className="d-none d-xl-block" />
                      Their testimonials reflect their unique experience. Your specific results will vary.
                    </i>
                  </p>
                  <TestimonialCircle id="wlc-christopher" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-lisa" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-cindy" />
                  <hr className="my-5" />
                  <TestimonialCircle id="wlc-robin-long" imgPosition="right" />
                </Col>
              </Row>
            </Container>
          </Section>

          <CtaSection id="whole-cta-2" />

          <Section id="whole-wont" color="white">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10">
                  <h2 className="section-only-heading text-center">
                    What You <i>Won’t</i> Find In <br className="d-none d-sm-block d-xl-none" />
                    WHOLE Life Club
                  </h2>
                  <hr className="my-5" />
                  <IconList iconColor="red">
                    <IconListItem icon={faTimes}>
                      <h3 className="section-only-heading">A book you buy but don’t read.</h3>
                    </IconListItem>
                  </IconList>
                  <p>
                    A good book is wonderful, but it can take ten hours or maybe more to read it cover to cover. Who has
                    time to spend weeks learning everything there is to know about a single health topic — even if it’s
                    one that will majorly impact your life?
                  </p>
                  <p>
                    That’s why you’ll get short, compact lessons each week, with links for further reading so you can do
                    as many deep dives as you wish.
                  </p>
                  <p>
                    Then, during Action Hours, you’ll get the “greatest hits” from world-class experts so you can be
                    sure you’re not missing vital insights that could literally change your life overnight.
                  </p>
                  <hr className="my-5" />
                  <IconList iconColor="red">
                    <IconListItem icon={faTimes}>
                      <h3 className="section-only-heading">A bunch of guesswork.</h3>
                    </IconListItem>
                  </IconList>
                  <p>
                    Don’t want to go down internet rabbit holes or wait for the next available appointment? We don’t,
                    either!
                  </p>
                  <p>
                    That’s why the WHOLE Life Club moderated forum is the place to go when you need
                    scientifically-backed information.
                  </p>
                  <p>
                    Our team of health coaches are standing by to help you troubleshoot your unique health challenges,
                    answer nutrition questions, debunk fads, and point you to recipes for stress reduction, mood
                    support, disease prevention, and any other important topic that’s on your mind.
                  </p>
                  <hr className="my-5" />
                  <IconList iconColor="red">
                    <IconListItem icon={faTimes}>
                      <h3 className="section-only-heading">Another Facebook group.</h3>
                    </IconListItem>
                  </IconList>
                  <p>
                    Not everyone loves Facebook, so we created a warm space where you can focus on what you want, when
                    you want (without having your eyeballs sold to advertisers).
                  </p>
                  <p>
                    Connect with WHOLE Life Club registered dietitians and certified health coaches, and with new
                    friends who are on the same path and share your enthusiasm.
                  </p>
                  <p>Conversations are sorted by category, making it easy to ask your questions and find answers.</p>
                  <hr className="my-5" />
                  <IconList iconColor="red">
                    <IconListItem icon={faTimes}>
                      <h3 className="section-only-heading">A solo adventure.</h3>
                    </IconListItem>
                  </IconList>
                  <p>
                    Have you ever tried to make a New Year’s resolution without someone else who’s committed to the same
                    journey? If you try really hard, you might make it a while before you slip back to your old ways —
                    but it can be hard to make it stick on your own.
                  </p>
                  <p>
                    The WHOLE Life Club community is your liferaft to the next level of you. Post whenever you need a
                    cheerleader or some help brainstorming ideas.
                  </p>
                  <p>
                    Plus, you’ll be able to bring your loved ones along for the ride as you enjoy new recipes, watch the
                    Action Hours together, and inspire them to take great care of themselves, too.
                  </p>
                  <hr className="my-5" />
                  <IconList iconColor="red">
                    <IconListItem icon={faTimes}>
                      <h3 className="section-only-heading">Another night of uninspired dinners.</h3>
                    </IconListItem>
                  </IconList>
                  <p>There’s so much more to healthy eating than frozen bean burritos and boring salads.</p>
                  <p>
                    You’ll discover mouthwatering recipes you can whip up quickly and inexpensively, including
                    veggie-forward versions of comfort classics as well as new dishes that will become your go-to
                    favorites.
                  </p>
                  <p>
                    Go ahead, tantalize your taste buds — even the pickiest eater will want these recipes on heavy
                    rotation!
                  </p>
                  <hr className="my-5" />
                  <IconList iconColor="red">
                    <IconListItem icon={faTimes}>
                      <h3 className="section-only-heading">
                        A drill sergeant telling you what <br className="d-none d-xl-block" />
                        you’re “supposed” to do.
                      </h3>
                    </IconListItem>
                  </IconList>
                  <p>
                    When you have people cheering you on — as well as health tips and hacks spoonfed to you — it all
                    adds up!
                  </p>
                  <p>You’ll be able to do a little bit better every day, and truly enjoy the process.</p>
                  <p>
                    The entire focus of WHOLE Life Club is to help open your eyes, get results, and have fun so you keep
                    coming back.
                  </p>
                  <p>
                    You’ll be hearing from Ocean regularly, too, with videos and action tips and potent resources — and
                    trust us, these are emails you’ll actually want to read!
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-testimonial-" color="black" innerClass="text-white">
            <Container>
              <Row>
                <Col>
                  <TestimonialCircle id="wlc-laurie-c" className="text-white" />
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="whole-motivation"
            color="white"
            imgPosition="right"
            imgUrl={require('static/wlc/couple-happy-bowls-square.jpg')}
            className="text-center text-lg-left"
          >
            <h2 className="section-heading mb-3">
              Motivation To Live <br className="d-none d-sm-block" />
              Your Healthiest Life
            </h2>
            <h4 className="text-400 mt-0">
              WHOLE Life Club doesn’t just <br className="d-none d-sm-block" />
              give you information.
            </h4>
            <h4 className="text-400 mt-0">It gives you power.</h4>
            <h4 className="text-400 my-4">
              <i>
                One meal,
                <br />
                One discovery,
                <br />
                One conversation,
                <br />
                One new friend...
              </i>
            </h4>
            <h4 className="text-400 mt-0">At a time.</h4>
          </SectionImage>

          <SectionImage
            id="whole-pressure"
            color="light-gray"
            borderColor="light-gray"
            className="section-noangle-lg"
            imgUrl={require('static/wlc/woman-cellphone-square.jpg')}
          >
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading">
                    A Few Words <br className="d-none d-sm-block" />
                    About Social Pressure.
                  </h2>
                  <p>
                    <i>Have you ever given in to peer pressure?</i> It’s quite common.
                  </p>
                  <p>It’s harder to have a healthy lifestyle when your friends and family aren’t fully on board.</p>
                  <p>
                    This stress becomes even more intense when you have an underlying illness or condition that no one
                    else seems to understand.
                  </p>
                  <p>
                    When people around you are bonding over unhealthy foods, you may feel sad, disconnected, or
                    disappointed.
                  </p>
                  <p>They might also express discomfort that you’re not joining in.</p>
                  <p>
                    Worst of all, you might compromise your food values to fit in, or for fear of hurting someone’s
                    feelings (and, ultimately, you may regret it).
                  </p>
                  <p>Unless you have ample support, your social life can pull you in unhealthy directions.</p>
                  <p className="mb-0">
                    Unless you have ample support, your social life can pull you in unhealthy directions.
                  </p>
                </Col>
              </Row>
            </Container>
          </SectionImage>

          <SectionImage
            id="whole-pressure-2"
            color="light-gray"
            imgPosition="right"
            className="section-image-offset-top-desktop"
            innerClass="pt-lg-3"
            imgUrl={require('static/wlc/woman-yellow-sweater.jpg')}
          >
            <h2 className="section-heading">The Solution: A Welcoming, Supportive Community</h2>
            <p>
              The instant you join, you’ll gain access to a moderated community of health-minded people who are ready to
              help you overcome whatever challenges you may face — now, or in the future.
            </p>
            <p>This is why Ocean offers ample tips to help you navigate any social situation with ease.</p>
            <p>This is your community, ready to cheer you on and celebrate your victories and milestones...</p>
            <h3 className="mt-4">And, to motivate you from “I know I should,” to “I’m doing it!”</h3>
          </SectionImage>

          <Section id="whole-testimonial-" color="white">
            <Container>
              <Row>
                <Col>
                  <TestimonialCircle id="wlc-genevieve-d" />
                </Col>
              </Row>
            </Container>
          </Section>

          <CtaSection
            id="whole-cta-2"
            title={
              <Row className="text-center text-white mb-4">
                <Col>
                  <h2 className="section-heading">Be Inspired, Instantly.</h2>
                  <h4 className="text-400 mt-0">Get Instant Access to Everything</h4>
                  <h4 className="text-400 mt-0">Make Healthy Eating Sustainable</h4>
                  <h4 className="text-400 mt-0 mb-5">Lock In Your Price Now — It Will Never Go Up</h4>
                  <p>
                    <b>Special opportunity for 14-Day Plant-Powered Accelerator participants ONLY.</b>{' '}
                    <br className="d-none d-lg-block" />
                    Your chance to join this amazing community for 48% off ends in:
                  </p>
                  <SpecialTimer />
                </Col>
              </Row>
            }
          />

          <Section id="whole-why" color="white">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col>
                  <h2 className="section-heading text-center mb-5">Why Join WHOLE Life Club?</h2>
                  <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-money.jpg')}>
                    <h4 className="section-heading">Save money.</h4>
                    <p>
                      You’ll love having inspiration and recipes to cook more healthy meals at home on a regular basis.
                      With just one or two less takeout meals a month, your membership will pay for itself — and with
                      fewer trips to the doctor and pharmacy aisle, you could save <i>thousands.</i>
                    </p>
                  </ImgCard>
                  <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-time.jpg')}>
                    <h4 className="section-heading">Save time.</h4>
                    <p>Learn the must-know food techniques you were never taught for saving time while cooking.</p>
                    <p>
                      Plus, all recipes can be adapted for gluten-, sugar-, oil-, and/or salt-free diets, so you won’t
                      waste time hunting for meals you can eat.
                    </p>
                  </ImgCard>
                  <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-health.jpg')}>
                    <h4 className="section-heading">Save your health.</h4>
                    <p>Don’t miss out on critical health topics that every single person needs to know.</p>
                    <p>
                      WHOLE Life Club is like <br className="d-none d-sm-block d-md-none" />a total health{' '}
                      <br className="d-none d-lg-block d-xl-none" />
                      encyclopedia, <br className="d-none d-sm-block d-md-none" />
                      giving you the resources you need, when you need them.
                    </p>
                  </ImgCard>
                  <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-hassle.jpg')}>
                    <h4 className="section-heading">Save yourself hassle.</h4>
                    <p>
                      Whether you’re a seasoned chef or a beginner, you’ll get tips and tricks to help you make cooking
                      and menu planning fun.
                    </p>
                  </ImgCard>
                  <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-planet.jpg')}>
                    <h4 className="section-heading">Save the planet.</h4>
                    <p>
                      Putting more veggies <br className="d-none d-sm-block d-md-none" />
                      on your plate is one of the best things you can do to save soil and water, reduce pesticides,
                      fight climate change, and preserve the beautiful Earth we call home.
                    </p>
                  </ImgCard>
                  <h3 className="text-center mt-4">
                    No overwhelm.
                    <br />
                    No confusion.
                    <br />
                    No boredom in the kitchen.
                  </h3>
                  <h3 className="m-0 text-center">
                    Just you making strides, <br className="d-none d-sm-block d-md-none" />
                    one day at a time!
                  </h3>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="whole-guarantee" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <GuaranteeBox
                    title={
                      <>
                        Our 60-Day Better Than Money Back <br className="d-none d-md-block" />
                        Risk-Free Guarantee
                      </>
                    }
                  >
                    <p>Take up to 60 days to see what WHOLE Life Club is all about.</p>
                    <p className="mb-4">We guarantee that you and your family will:</p>
                    <IconList>
                      <IconListItem>
                        Have the tools and information to improve your health right away, helping you eat better and
                        enjoy more vitality.
                      </IconListItem>
                      <IconListItem>Feel empowered knowing the truth about the foods you eat.</IconListItem>
                      <IconListItem>Make meaningful progress towards YOUR healthy eating goals.</IconListItem>
                    </IconList>
                    <p>
                      Your satisfaction is important to us! If for some reason you aren’t 100% thrilled with your
                      purchase, you have 60 days to request a prompt, total refund. Email us at{' '}
                      <a className="link-black text-underline" href="mailto:support@foodrevolution.org">
                        support@foodrevolution.org
                      </a>{' '}
                      or phone us at{' '}
                      <a className="link-black text-underline" href="tel:8318244779">
                        (831) 824-4779
                      </a>
                      . You can also hit reply to any email we send you with your request.
                    </p>
                    <p className="mb-0">
                      If for any reason you request a refund, everything you've already downloaded is still yours to
                      keep. That’s why we call our guarantee “risk-free!”
                    </p>
                  </GuaranteeBox>
                </Col>
              </Row>
            </Container>
          </Section>

          <CtaSection id="whole-cta-3" className="section-angle-last" angle="none" />
        </>
      </div>

      <Footer />
      {noExitIntent ? null : <>{/* modal */}</>}
    </div>
  );
}
