import React from 'react';
import classNames from 'classnames';
import './Guarantee.scss';

export default function GuaranteeBox({title, children, ...props}) {
  return (
    <div className={classNames(`guarantee-box rounded background-white box-shadow-black-50`, props.className)}>
      <h2 className="section-heading guarantee-heading text-center text-sm-left">{title}</h2>
      {children}
    </div>
  );
}
