import React, {Component} from 'react';

import {callPlayer, getSDK} from 'react-player/lib/utils';
import {extractStreamId} from './extractStreamId';
import {useIframeSrc} from './useIframeSrc';

const SDK_URL = 'https://embed.cloudflarestream.com/embed/sdk.latest.js';
const SDK_GLOBAL = 'Stream';

export default class CloufdlareStream extends Component {
  static displayName = 'Stream';
  static canPlay = (url) =>
    /\.videodelivery\.net\/([a-z0-9]+)(\/watch\/?)?$/.test(url) ||
    /\.cloudflarestream\.com\/([a-z0-9]+)(\/watch\/?)?$/.test(url);
  static forceLoad = true; // Prevent checking isLoading when URL changes
  callPlayer = callPlayer;
  duration = null;
  currentTime = null;
  secondsLoaded = null;

  componentDidMount() {
    this.props.onMount && this.props.onMount(this);
  }

  // Proxy methods to prevent listener leaks
  onReady = (...args) => {
    return this.props.onReady(...args);
  };
  //onReady = console.log;
  onPlay = (...args) => this.props.onPlay(...args);
  onBuffer = (...args) => this.props.onBuffer(...args);
  onBufferEnd = (...args) => this.props.onBufferEnd(...args);
  onPause = (...args) => this.props.onPause(...args);
  onEnded = (...args) => this.props.onEnded(...args);
  onError = (...args) => this.props.onError(...args);
  onEnablePIP = (...args) => this.props.onEnablePIP(...args);
  onSeek = (...args) => this.props.onSeek(...args);

  load(url) {
    this.duration = null;
    getSDK(SDK_URL, SDK_GLOBAL).then((Stream) => {
      if (!this.container) return;
      this.player = Stream(this.container);
      this.player.addEventListener('loadeddata', this.onReady);
      this.player.addEventListener('play', this.onPlay);
      this.player.addEventListener('waiting', this.onBuffer);
      this.player.addEventListener('playing', this.onBufferEnd);
      this.player.addEventListener('pause', this.onPause);
      this.player.addEventListener('seeked', this.onSeek);
      this.player.addEventListener('ended', this.onEnded);
      this.player.addEventListener('error', this.onError);
    }, this.props.onError);
  }

  refreshDuration() {
    this.player.getDuration().then((duration) => {
      this.duration = duration;
    });
  }

  play() {
    const promise = this.player.play();
    if (promise) {
      promise.catch(this.props.onError);
    }
  }

  pause() {
    this.player.pause();
  }

  stop() {
    this.player.pause();
  }

  seekTo(seconds) {
    this.player.currentTime = seconds;
  }

  setVolume(fraction) {
    this.player.volume = fraction;
  }

  setLoop(loop) {
    //this.callPlayer('setLoop', loop);
    return null;
  }

  setPlaybackRate(rate) {
    this.player.playbackRate = rate;
  }

  mute = () => {
    this.player.muted = true;
  };

  unmute = () => {
    this.player.muted = false;
  };

  getDuration() {
    if (!this.player) return null;
    const {duration, seekable} = this.player;
    // on iOS, live streams return Infinity for the duration
    // so instead we use the end of the seekable timerange
    if (duration === Infinity && seekable.length > 0) {
      return seekable.end(seekable.length - 1);
    }
    return duration;
  }

  getCurrentTime() {
    if (!this.player) return null;
    return this.player.currentTime;
  }

  getSecondsLoaded() {
    if (!this.player) return null;
    const {buffered} = this.player;
    if (buffered.length === 0) {
      return 0;
    }
    const end = buffered.end(buffered.length - 1);
    const duration = this.getDuration();
    if (end > duration) {
      return duration;
    }
    return end;
  }

  ref = (container) => {
    this.container = container;
  };

  render() {
    const {display, controls, config: {stream: {options} = {options: {}}} = {stream: {options: {}}}} = this.props;
    const style = {
      position: 'relative',
      paddingTop: '56.25%'
    };
    const url = useIframeSrc(extractStreamId(this.props.url), {controls, ...options});
    return (
      <div className={this.props.className} style={style} key={this.props.url}>
        <iframe
          src={url}
          ref={this.ref}
          frameBorder={0}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen
          style={{border: 'none', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}}
        />
      </div>
    );
  }
}
