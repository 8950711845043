import React from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {Col, Container, Row} from 'reactstrap';
import ShowIf from 'funnel-schedule/ShowIf';
import classNames from 'classnames';
import CountdownTimer from '../../Elements/CountdownTimer';
import './TimerSection.scss';

const launch = new Date('2020-06-02T00:00:00-07:00').getTime();

export default function SalesTimerSection(props) {
  return (
    <div className={classNames(`section-countdown text-center text-white`, props.className)}>
      <Container>
        <Row className="d-flex align-items-center">
          <Col xs="12" className="timer">
            <h4 className="timer-heading">This Special Offer Ends In:</h4>
            <CountdownTimer time={launch} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
