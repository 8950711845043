import React, {useCallback, useState, useRef, PureComponent, Component} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {Col, Container, Row, Button} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import ReactMarkdown from 'react-markdown';
import Modal from 'components/Modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faRedo, faUtensils, faMicrophone, faPrint} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import './Tile.scss';
import {recipe, recipeTile, recipeTileWlc} from 'data/recipe.json';
import {days} from 'data/speaker.json';

export default function Tile(props) {
  const {
    phases: {broadcast: broadcastSchedule = {phase: 'before', day: 0}}
  } = usePhaseState();
  broadcastSchedule.day =
    broadcastSchedule.day && !isNaN(broadcastSchedule.day)
      ? Math.min(8, Math.max(0, parseInt(broadcastSchedule.day)))
      : 0;

  return (
    <Button
      color="tile"
      className="tile d-flex flex-column h-100"
      href={((type) => {
        switch (type) {
          case 'broadcast':
            return '/broadcasts/';
          case 'replay':
            return '/replays/';
          case 'schedule':
            return '/schedule/';
          default:
            return '/';
        }
      })(props.type)}
      target="_blank"
      block
    >
      <div className="tile-header">
        {((type) => {
          switch (type) {
            case 'broadcast':
              return 'Broadcasts';
            case 'replay':
              return 'Replays';
            default:
              return null;
          }
        })(props.type)}
      </div>
      <div className="tile-content d-flex flex-column h-100">
        <div className="d-flex align-items-center my-auto">
          <div className="tile-icon">
            {((type) => {
              switch (type) {
                case 'broadcast':
                  return <FontAwesomeIcon icon={faMicrophone} />;
                case 'replay':
                  return <FontAwesomeIcon icon={faRedo} />;
                default:
                  return null;
              }
            })(props.type)}
          </div>
          <div className="tile-copy text-left">
            {((type) => {
              switch (type) {
                case 'broadcast':
                  return <>Be sure to join us if you can for each Action Hour in the WHOLE Life Action Hour Series.</>;
                case 'replay':
                  return <>The replays of the Action Hours will stay up until the end of the Series.</>;
                default:
                  return null;
              }
            })(props.type)}
          </div>
        </div>
        <div className="tile-button">
          <span className="btn btn-green btn-block tile-btn text-small">
            {((type) => {
              switch (type) {
                case 'broadcast':
                  return 'Click here to join us!';
                case 'replay':
                  return 'Click here for what’s on replay now!';
                case 'schedule':
                  return 'Click here to view the schedule!';
                default:
                  return null;
              }
            })(props.type)}
          </span>
        </div>
      </div>
    </Button>
  );
}

// Recipe Tile
function RecipeTileContent({
  id,
  name,
  image,
  description,
  servings,
  prep,
  cook,
  total,
  ingredients,
  ingredientsSetTwoHeading,
  ingredientsSetTwo,
  instructions,
  notes,
  credit,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  const handleClickPrint = () => {
    try {
      let printCommand = document.execCommand('print', false, null);
      // zp .execCommand returns false on 'successful failure'
      if (!printCommand) window.print();
    } catch (e) {
      window.print();
    }
  };

  const componentRef = useRef();

  return (
    <Button
      color="tile"
      className={classNames(`tile tile-recipe d-flex align-items-stretch flex-column h-100 recipe-${id}`)}
      onClick={onClick}
      block
    >
      <div className="tile-header">Recipe of the Day</div>
      <div className="tile-content d-flex flex-column h-100">
        <div className="d-flex align-items-center my-auto">
          <div className="tile-icon">
            <img className="recipe-img" src={image} />
          </div>
          <ReactMarkdown className="tile-copy text-left" source={name || 'Recipe Name'} />
        </div>
        <div className="tile-button">
          <span className="tile-btn text-small btn btn-block btn-green">Click here to view the recipe!</span>
          <Modal className="recipe-modal" isOpen={isOpen} toggle={toggle} size="lg">
            <Container className="pb-4 px-3 px-md-4 px-lg-5" fluid>
              <Row>
                <Col>
                  <div className="recipe-header">
                    <ReactToPrint
                      trigger={() => (
                        <Button className="d-print-none btn-print text-white text-small" color="green">
                          <FontAwesomeIcon className="mr-2" icon={faPrint} />
                          Print Recipe
                        </Button>
                      )}
                      content={() => componentRef.current}
                      bodyClass="frn-body-print"
                    />

                    <ReactMarkdown className="recipe-name text-800 text-h3" source={name || 'Recipe Name'} />
                  </div>

                  <RecipePrintContent
                    id={id}
                    name={name}
                    image={image}
                    description={description}
                    servings={servings}
                    prep={prep}
                    cook={cook}
                    total={total}
                    ingredients={ingredients}
                    ingredientsSetTwoHeading={ingredientsSetTwoHeading}
                    ingredientsSetTwo={ingredientsSetTwo}
                    instructions={instructions}
                    notes={notes}
                    credit={credit}
                    {...props}
                    ref={componentRef}
                  />
                </Col>
              </Row>
            </Container>
          </Modal>
        </div>
      </div>
    </Button>
  );
}

function RecipeTileContentLink({
  id,
  name,
  image,
  description,
  servings,
  prep,
  cook,
  total,
  ingredients,
  ingredientsSetTwoHeading,
  ingredientsSetTwo,
  instructions,
  notes,
  credit,
  ...props
}) {
  return (
    <Button
      color="tile"
      className={classNames(`tile tile-recipe d-flex align-items-stretch flex-column h-100 recipe-${id}`)}
      href="/recipes/"
      target="blank"
      block
    >
      <div className="tile-header">Recipe of the Day</div>
      <div className="tile-content d-flex flex-column h-100">
        <div className="d-flex align-items-center my-auto">
          <div className="tile-icon">
            <img className="recipe-img" src={image} />
          </div>
          <ReactMarkdown className="tile-copy text-left" source={name || 'Recipe Name'} />
        </div>
        <div className="tile-button">
          <span className="tile-btn text-small btn btn-block btn-green">Click here to view the recipe!</span>
        </div>
      </div>
    </Button>
  );
}

export function RecipeTile({id, ...props}) {
  const recipes = recipe.find((e) => e.id === id);
  return recipe ? <RecipeTileContent {...recipes} {...props} /> : null;
}

export function RecipeDay({day = 0, ...props}) {
  const {recipe} = days[day];
  return <RecipeTile className={recipe} id={recipe} {...props} />;
}

export function RecipeWlcDay(props) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: ''
          }
        ]
      }
    }
  } = usePhaseState();

  const currentActionHour = defaultSchedule.currentActionHour;
  const recipe = defaultSchedule.actionHours[currentActionHour].recipe;

  return <RecipeTile className={recipe} id={recipe} {...props} />;
}

export function RecipeTileLink({id, ...props}) {
  const recipes = recipe.find((e) => e.id === id);
  return recipe ? <RecipeTileContentLink {...recipes} {...props} /> : null;
}

export function RecipeDayLink({day = 0, ...props}) {
  const {recipe} = days[day];
  return <RecipeTileLink className={recipe} id={recipe} {...props} />;
}

class RecipePrintContent extends PureComponent {
  render() {
    return (
      <div className="recipe-print-content">
        <ReactMarkdown className="recipe-name text-800 text-h3" source={this.props.name || 'Recipe Name'} />
        <ReactMarkdown source={this.props.description || 'Recipe description'} />
        <p>
          <span className="recipe-servings">
            <strong>Serves:</strong> {this.props.servings}
          </span>
          <br />
          <span className="recipe-prep">
            <strong>Prep Time:</strong> {this.props.prep}
          </span>
          <br />
          <span className="recipe-cook">
            <strong>Cook Time:</strong> {this.props.cook}
          </span>
          <br />
          <span className="recipe-total">
            <strong>Total Time:</strong> {this.props.total}
          </span>
          <br />
        </p>
        <h6>Ingredients</h6>
        <ReactMarkdown className="recipe-ingredients" source={this.props.ingredients || 'Recipe ingredients'} />
        {this.props.ingredientsSetTwoHeading ? (
          <>
            <p>
              <strong>{this.props.ingredientsSetTwoHeading}</strong>
            </p>
            <ReactMarkdown
              className="recipe-ingredients"
              source={this.props.ingredientsSetTwo || 'Recipe ingredients'}
            />
          </>
        ) : null}
        <h6>Directions</h6>
        <ReactMarkdown className="recipe-instructions" source={this.props.instructions || 'Recipe instructions'} />
        {this.props.notes ? (
          <>
            <h6>Chef's Notes</h6>
            <ReactMarkdown className="recipe-notes" source={this.props.notes || 'Recipe notes'} />
          </>
        ) : null}
        {this.props.credit ? (
          <ReactMarkdown className="recipe-credit text-16" source={this.props.credit || 'Recipe credit'} />
        ) : null}
      </div>
    );
  }
}

// Recipe Tile No Content
export function RecipeTileBox({id, ...props}) {
  const recipes = props.recipeTileWlc ? recipeTileWlc.find((e) => e.id === id) : recipeTile.find((e) => e.id === id);
  return recipeTile ? <RecipeTileBoxContent {...recipes} {...props} /> : null;
}

function RecipeTileBoxContent({id, name, image, ...props}) {
  let imageStyle = {
    backgroundImage: `url('${image}')`
  };

  return (
    <div
      className={classNames(
        `box-recipe d-flex align-items-stretch flex-column h-100 background-white box-shadow-black-50 recipe-${id}`
      )}
    >
      <Row className="d-flex align-items-stretch h-100 mx-0">
        <Col xs="4" className="box-recipe-image background-cover background-image-center h-100" style={imageStyle} />
        <Col xs="8" className="py-3 d-flex flex-column">
          <ReactMarkdown className="box-recipe-name my-auto" source={name || 'Recipe Name'} />
        </Col>
      </Row>
    </div>
  );
}
