import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import SalesIndex from './join';

export default function () {
  useEffect(() => {
    navigate('/a/join/' + location.search);
  }, []);
  return <SalesIndex ctaOverride="salesPrice" ctaNoTimer />;
}
