import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCircle} from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import './IconList.scss';

export default function IconList({iconColor = 'green', children, ...props}) {
  return <ul className={classNames(`icon-list icon-list-${iconColor} fa-ul`, props.className)}>{children}</ul>;
}

export function IconListItem({icon = faCheck, children, ...props}) {
  return (
    <li className={classNames(`icon-list-item d-flex align-items-center`, props.className)}>
      {props.number ? (
        <div className="icon-list-number-wrap fa-layers fa-li">
          <FontAwesomeIcon className="icon-list-number-circle" icon={faCircle} />
          <div className="icon-list-number-value fa-layers-text text-800">{props.number}</div>
        </div>
      ) : (
        <FontAwesomeIcon className="fa-li" icon={icon} />
      )}

      <div className="icon-list-item-content">{children}</div>
    </li>
  );
}
