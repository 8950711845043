import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import SalesIndex from './join';
import track from 'utils/track';

export default function () {
  useEffect(
    () =>
      track('Product Viewed', {
        product_id: 'A-FDA',
        sku: 'A-FDA',
        name: '14-Day Plant-Powered Accelerator',
        variant: 'fda-special',
        value: 7,
        currency: 'usd'
      }),
    []
  );
  return <SalesIndex slug="join-discount" ctaOverride="salesPrice" ctaNoTimer />;
}
