import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Col, Container, Row} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import CheckoutButton from 'components/CheckoutButton';
import CountdownTimer from '../Elements/CountdownTimer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faCartPlus} from '@fortawesome/pro-light-svg-icons';
import './SalesCta.scss';
import logoTrees from 'static/logo/logo-trees-for-future-white.png';
import classNames from 'classnames';
import SalesTimerSection from 'components/Whole/TimerSection';
import {useUserState} from 'contexts/user';
import {usePhaseState, usePhaseDispatch} from 'funnel-schedule/phase-context';
import IconList, {IconListItem} from 'components/Elements/IconList';

const {SF_API_URL} = process.env;
const {SITE_ID} = process.env;

export function SalesDisclaimer({iconColor = 'green', ...props}) {
  return (
    <div className="disclaimer-wrap">
      <div className="disclaimer-cards">
        <img
          className="img-fluid img-cc"
          src="https://cdn.foodrevolution.org/global/images/payments-accepted-20240424.svg"
          width={1800}
          height={175}
        />
      </div>
      <div className="disclaimer-copy text-small">
        <p className={props.noGuaranteeText ? 'mb-0' : 'mb-2'}>
          <FontAwesomeIcon className={classNames(`offer-box-security-icon text-${iconColor} mr-2`)} icon={faLock} /> For
          your security, all orders are processed on a secured server.
        </p>
        {props.noGuaranteeText ? null : (
          <p className="mb-0">Your purchase is protected by our 60-day money back guarantee.</p>
        )}
      </div>
    </div>
  );
}

export default function SalesBox({
  offerBoxBackground = 'white',
  offerBoxBorder = 'border-dashed border-color-green-100 border-2',
  btnText = 'Buy Now',
  imgSrc = 'https://cdn.foodrevolution.org/hhc/hhc-product-image-v2b.png',
  imgWidth = '1200',
  imgHeight = '667',
  imgAlt = 'Healthy Heart product graphic',
  ...props
}) {
  return (
    <div className={classNames(`cta-sales cta-sales-${props.className}`)}>
      {props.title ? <h2 className="section-heading text-white text-center mb-3">{props.title}</h2> : null}
      {props.subTitle ? <p className="text-white text-center mb-5">{props.subTitle}</p> : null}

      <div
        className={classNames(
          `offer-box offer-box-shadow px-3 py-4 px-sm-4 px-md-5 py-md-5 text-center background-${offerBoxBackground} ${offerBoxBorder}`
        )}
      >
        {props.primaryHeading ? props.primaryHeading : null}

        {props.noImage ? null : (
          <Row className="d-flex justify-content-center">
            <Col xs="10" md="8" xl="6">
              <img src={imgSrc} width={imgWidth} height={imgHeight} alt={imgAlt} />
            </Col>
          </Row>
        )}

        {props.heading ? <div className="offer-box-heading">{props.heading}</div> : null}

        {props.offerTextNoPrice ? (
          <div className="offer-box-offer">
            <div className="text-h3 text-800 mt-4">{props.regularTextNoPrice}</div>
            <div className="offer-box-offer-text mt-3">{props.offerTextNoPrice}</div>
          </div>
        ) : (
          <>
            {props.offerPrice ? (
              <>
                {props.offerTextInline ? (
                  <div className="offer-box-offer-text-inline">
                    {props.offerTextInline} <del className="regular-price">${props.regularPrice}</del>{' '}
                    <span className="offer-price">${props.offerPrice}</span>
                  </div>
                ) : (
                  <div className="offer-box-offer">
                    <div className="text-h3 text-green text-800">
                      All For Just:{' '}
                      <span className="offer-box-offer-text-regular text-medium-gray">${props.regularPrice}</span>
                    </div>
                    <div className="offer-box-offer-text">
                      {props.offerText} ${props.offerPrice}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="offer-box-regular-price">
                <span className="text-black">Your Price:</span> ${props.regularPrice}
              </div>
            )}
          </>
        )}

        {props.timerText ? (
          <div className="offer-box-timer">
            <div className="offer-box-timer-text">{props.timerText}</div>
            <div className="countdown-dark">
              <CountdownTimer time={props.date} />
            </div>
          </div>
        ) : null}

        <CheckoutButton
          className="offer-box-button"
          sku={props.sku}
          price={props.offerPrice ? props.offerPrice : props.price}
          slug={props.slug}
        >
          <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> {btnText}
        </CheckoutButton>

        <SalesDisclaimer />

        {props.paymentPlanPrice ? (
          <div className="offer-box-payment-plan mt-3">
            Prefer a 3-month payment plan? We’ve got you covered!
            <CheckoutButton
              color="link"
              className="btn-link-checkout ml-1"
              sku={props.sku}
              price={props.paymentPlanPrice}
              slug={props.slug}
              queryParams={{opt: 1}}
            >
              Join now for just ${props.paymentPlanPrice}/month!
            </CheckoutButton>
          </div>
        ) : null}
      </div>

      {props.trees ? (
        <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
          <Col xs="11" lg="8" className="mb-4 mb-lg-0">
            <h6 className="section-only-heading text-center text-lg-left text-400">
              For every new order, we make a donation to Trees for the Future, enabling them to plant another organic
              fruit or nut tree in a low-income community.
            </h6>
          </Col>
          <Col xs="11" lg="3" className="text-center">
            <img className="logo-trees" src={logoTrees} />
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

export function PurchaseCard({
  title = <h3 className="section-only-heading">Get Started Now!</h3>,
  btnText = 'Buy Now',
  ...props
}) {
  return (
    <div className="purchase-card">
      <div className="purchase-card-header background-black text-white rounded-top p-3 p-lg-4">
        <div className="purchase-card-title text-uppercase text-center m-0">{title}</div>
      </div>
      <div className="purchase-card-content background-white rounded-bottom px-4 px-lg-5 py-4">
        {props.heading ? <div className="purchase-card-heading">{props.heading}</div> : null}
        {props.copy ? <div className="purchase-card-copy">{props.copy}</div> : null}
        {props.timerText ? (
          <div className="offer-box-timer text-center mt-4">
            <div className="offer-box-timer-text">{props.timerText}</div>
            <div className="countdown-dark">
              <CountdownTimer time={props.date} />
            </div>
          </div>
        ) : null}
        <div className="text-center">
          <CheckoutButton
            className="offer-box-button"
            sku={props.sku}
            price={props.offerPrice ? props.offerPrice : props.price}
            slug={props.slug}
          >
            <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> {btnText}
          </CheckoutButton>
          <SalesDisclaimer />
        </div>
      </div>
    </div>
  );
}

export function AcceleratorSales({slug, ...props}) {
  return (
    <PurchaseCard
      className="offer-7"
      regularPrice="47"
      offerPrice="7"
      sku="A-FDA"
      slug={slug}
      title={
        <h3 className="section-only-heading">
          What’s Included with the <br className="d-none d-sm-block" />
          14-Day Plant-Powered Accelerator?
        </h3>
      }
      copy={
        <>
          <div className="list-simple-check-wrap px-md-4">
            <IconList className="text-base">
              <IconListItem>
                14 simple but essential lessons (a new one delivered <br className="d-none d-md-block" />
                to your inbox every day for two weeks)
              </IconListItem>
              <IconListItem>
                Make consistent progress and see results with <br className="d-none d-sm-block" />
                these 14 action steps
              </IconListItem>
              <IconListItem>
                14 crave-worthy (and supremely nourishing) recipes <br className="d-none d-md-block" />
                to launch your healthier life
              </IconListItem>
              <IconListItem>
                Links to 72 of our most popular, useful, and fact-based articles (which link to{' '}
                <b>
                  <i>dozens</i>
                </b>{' '}
                more fabulous recipes and hundreds of cited medical journal articles)
              </IconListItem>
            </IconList>
          </div>
          <h4 className="text-center mt-4 mb-2">
            Your Price: <del>$47</del>
          </h4>
          <h2 className="text-center text-green mt-0 mb-2">JOIN NOW for only $7</h2>
          <h5 className="text-center text-400 mt-0">(Save 85%!)</h5>
        </>
      }
      noImage
      {...props}
    />
  );
}

export function AcceleratorSalesFull(props) {
  return (
    <PurchaseCard
      className="offer-47"
      regularPrice="47"
      sku="A-FDA"
      slug="fda"
      title={
        <h3 className="section-only-heading">
          What’s Included with the <br className="d-none d-sm-block" />
          14-Day Plant-Powered Accelerator?
        </h3>
      }
      copy={
        <>
          <div className="list-simple-check-wrap px-md-4">
            <IconList className="text-base">
              <IconListItem>
                14 simple but essential lessons (a new one delivered <br className="d-none d-md-block" />
                to your inbox every day for two weeks)
              </IconListItem>
              <IconListItem>
                Make consistent progress and see results with <br className="d-none d-sm-block" />
                these 14 action steps
              </IconListItem>
              <IconListItem>
                14 crave-worthy (and supremely nourishing) recipes <br className="d-none d-md-block" />
                to launch your healthier life
              </IconListItem>
              <IconListItem>
                Links to 72 of our most popular, useful, and fact-based articles (which link to{' '}
                <b>
                  <i>dozens</i>
                </b>{' '}
                more fabulous recipes and hundreds of cited medical journal articles)
              </IconListItem>
            </IconList>
          </div>
          <h2 className="text-center text-green mt-4 mb-2">Your Price: $47</h2>
        </>
      }
      noImage
      {...props}
    />
  );
}

export function MasterclassCta247(props) {
  return (
    <SalesBox
      className="offer-247"
      regularPrice="497"
      offerPrice="247"
      sku="hhc"
      slug="hhc-masterclass"
      paymentPlanPrice="89"
      regularTextNoPrice={
        <>
          Regular Price: <del>$497</del>
        </>
      }
      offerTextNoPrice={
        <>
          Enroll TODAY for just <br className="d-none d-sm-block d-md-none" />
          $247 + Get <br className="d-none d-lg-block d-xl-none" />
          the Heart Health Collection for FREE! <br className="d-none d-sm-block d-md-none d-lg-block d-xl-none" />
          (a $97 value)
        </>
      }
      {...props}
    />
  );
}

export function SalesCta297(props) {
  return (
    <SalesBox
      className="offer-297"
      regularPrice="497"
      offerPrice="297"
      sku="hhc"
      slug="hhc-special"
      paymentPlanPrice="109"
      primaryHeading={
        <>
          <h2 className="section-heading">
            Get lifetime access to the <br className="d-none d-sm-block" />
            <em>Healthy Heart Course</em> today!
          </h2>
        </>
      }
      regularTextNoPrice={
        <>
          Regular Price: <del>$497</del>
        </>
      }
      offerTextNoPrice={<>Right Now Only: $297</>}
      {...props}
    />
  );
}

export function SalesCta497(props) {
  return (
    <SalesBox
      className="offer-497"
      regularPrice="497"
      sku="hhc"
      slug="hhc"
      paymentPlanPrice="179"
      primaryHeading={
        <>
          <h2 className="section-heading">
            Get lifetime access to the <br className="d-none d-sm-block" />
            <em>Healthy Heart Course</em> today!
          </h2>
        </>
      }
      {...props}
    />
  );
}

// Dynamic Sales Box For User Experience
export function DynamicSalesCta({context = 'webinar', ...props}) {
  const {email, registrationId} = useUserState();
  const phases = usePhaseState().phases || {};
  const schedule = phases[context] || {};
  const {phase, pos, time} = schedule;
  return phase === 'after' || (!email && !registrationId) ? (
    <SalesCta497 {...props} />
  ) : (
    <SalesCta297
      offerTextNoPrice={
        <>
          Limited Time <br className="d-none d-sm-block d-lg-none" />
          Discount Price: $297
        </>
      }
      date={time * 1000 + 345600000}
      timerText="Your special discount ends in:"
      {...props}
    />
  );
}

export function SalesWlcAlumni(props) {
  return (
    <SalesBox
      className="wlc-hhc-alumni"
      imgSrc="https://wlc.foodrevolution.org/assets/images/action-collection.png"
      regularPrice="247"
      offerPrice="127"
      sku="wlc"
      slug="wlc-hhc-alumni"
      regularTextNoPrice={
        <>
          Regular price: $29/month <br className="d-none d-md-block" />
          (that’s $348 annually) or $247/year
        </>
      }
      offerTextNoPrice={
        <>
          Special <em>Healthy Heart</em> Offer: <br className="d-none d-lg-block" />
          $19/month or $127/year!
        </>
      }
      {...props}
    />
  );
}

export function UpsellSales({
  offerBoxBackground = 'background-white',
  offerBoxBorder = 'border-dashed border-green border-2 offer-box-shadow',
  offerBoxPadding = 'px-3 py-4 px-sm-4 px-md-5 py-md-5',
  ...props
}) {
  const offerBox = classNames('offer-box text-center', offerBoxPadding, offerBoxBackground, offerBoxBorder);

  return (
    <div className={classNames(`cta-sales cta-sales-${props.className}`)}>
      <div className={offerBox}>
        {props.heading ? <div className="upsell-box-heading">{props.heading}</div> : null}

        {props.imgSrc ? (
          <Row className="d-flex justify-content-center my-4">
            <Col xs="10" md="8" xl="6">
              <img
                className={props.imgClass}
                src={props.imgSrc}
                width={props.imgWidth}
                height={props.imgHeight}
                alt={props.imgAlt}
              />
            </Col>
          </Row>
        ) : null}

        <div className="upsell-box-confirm">
          <div className="mb-3">
            <CheckoutButton
              className="offer-box-button offer-box-button-upsell"
              sku={props.sku}
              price={props.offerPrice}
              slug={props.slug}
            >
              <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Yes, Add to Order
            </CheckoutButton>
          </div>
          {props.text ? <div className="upsell-box-text">{props.text}</div> : null}
        </div>

        <div className="upsell-box-no">
          <div className="mb-3">
            <Button className="btn-upsell-gray">No, thanks.</Button>
          </div>
          <div className="upsell-box-no-text">
            Clicking the “No” button above will take you to the products already in your cart.
          </div>
        </div>

        {props.noDisclaimer ? <SalesDisclaimer noGuaranteeText /> : <SalesDisclaimer />}
      </div>

      {props.noTrees ? null : (
        <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
          <Col xs="11" lg="8" className="mb-4 mb-lg-0">
            <h6 className="section-only-heading text-center text-lg-left text-400">
              For every new order, we make a donation to Trees for the Future, enabling them to plant another organic
              fruit or nut tree in a low-income community.
            </h6>
          </Col>
          <Col xs="11" lg="3" className="text-center">
            <img className="logo-trees" src={logoTrees} />
          </Col>
        </Row>
      )}
    </div>
  );
}

export function WlcUpsell(props) {
  return (
    <UpsellSales
      className="offer-wlc"
      heading="One-Time Offer Only!"
      text={
        <>
          Clicking the button above will sign you up for WHOLE Life Club for your free 3-month trial.{' '}
          <br className="d-none d-xl-block" />
          After 3 months, you’ll be auto-billed $19/month (that’s 33% off!).
        </>
      }
      sku="hhc"
      slug="hhc-wlc-us"
      {...props}
    />
  );
}

export function WlcUpsellMonth(props) {
  return (
    <UpsellSales
      className="offer-wlc"
      heading={
        <>
          Try it free — click below <br className="d-none d-sm-block d-lg-none" />
          to add to your order.
        </>
      }
      text={
        <>
          <p>You’ll get 30 days to try WHOLE Life Club, free.</p>
          <p>
            Clicking the button above will sign you up for WHOLE Life Club for your free 30 day trial.{' '}
            <br className="d-none d-xl-block" />
            After that, your membership will renew for $99/year unless you cancel before your trial expires. That’s just
            27 cents a day! This price is locked in and will never expire, so long as you keep your membership active.
          </p>
        </>
      }
      sku="WLC"
      slug="fda-wlc-us"
      {...props}
    />
  );
}

export function UhcUpsell(props) {
  return (
    <UpsellSales
      className="offer-uhc"
      heading="One-Time Offer Only!"
      text={
        <>
          Clicking “Yes” above will add the <strong>Ultimate Health Collection</strong> to your purchase now, for an
          additional $47.
        </>
      }
      sku="hhc"
      slug="hhc-uhc21-us"
      imgSrc="https://cdn.foodrevolution.org/uhc/uhc21-product-image.png"
      imgWidth="1000"
      imgHeight="416"
      imgAlt="Ultimate Health Collection product image"
      {...props}
    />
  );
}
