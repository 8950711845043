

  
// Template Map
export default {
  '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404': require('/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/whole.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/whole.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/wlc-upsell.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/wlc-upsell.jsx').default
}

export const notFoundTemplate = '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404'

