import React, {useEffect, useState} from 'react';
import {Col, Container, Input, Row} from 'reactstrap';
import classNames from 'classnames';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import track from 'utils/track';
import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import OptinModal, {OptinModalTrigger} from 'components/OptinModal';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Elements/Footer';
import Video from 'components/Video';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';
import IconList, {IconListItem} from 'components/Elements/IconList';
import CheckoutButton from 'components/CheckoutButton';
import {AcceleratorSales, AcceleratorSalesFull, SalesDisclaimer} from 'components/SalesCta';
import {ImgBulletCard} from 'components/Elements/ImgBullet';
import {TestimonialBubble, TestimonialCircle} from 'components/Testimonial';
import GuaranteeBox from 'components/Guarantee';
import {RecipeTileBox} from 'components/Elements/Tile';
import {recipeTile} from 'data/recipe.json';
import {ModuleList} from 'components/ModuleAccordion';
import {modules, modulesAd} from 'data/module.json';
import CountdownTimer from 'components/Elements/CountdownTimer';
import HeaderVideo from 'components/Elements/HeaderVideo';
import ModalExitSales from 'components/Modals/ModalExitSales';
import SalesContent from 'components/SalesSplit';
import useBalanceText from 'utils/useBalanceText';

export default function SalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = '',
  ctaNoTimer = false,
  ctaFlag = null,
  variant = null,
  slug = null,
  ...props
}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  const salesFullPrice = !ctaOverride || ctaOverride === 'fullPrice';
  const salesEnd = new Date(defaultSchedule.salesEnd).getTime();
  const launchContent = ctaFlag === 'launch';

  const btnText = <>Buy Now</>;

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'A-FDA',
      sku: 'A-FDA',
      name: '14-Day Plant-Powered Accelerator',
      variant: salesFullPrice ? 'fda-special' : 'fda',
      value: salesFullPrice ? 7 : 47,
      currency: 'usd'
    });
  }, [salesFullPrice]);

  function CtaSalesBox(props) {
    return (
      <>
        {ctaOverride ? (
          <>
            {ctaOverride === 'salesPrice' ? (
              <>
                {ctaNoTimer ? (
                  <AcceleratorSales slug={!salesFullPrice ? 'fda-special' : 'fda'} btnText={btnText} />
                ) : (
                  <AcceleratorSales
                    slug={!salesFullPrice ? 'fda-special' : 'fda'}
                    date={salesEnd}
                    timerText="Your discount expires in:"
                    btnText={btnText}
                  />
                )}
              </>
            ) : (
              <AcceleratorSalesFull btnText={btnText} />
            )}
          </>
        ) : (
          <AcceleratorSalesFull btnText={btnText} />
        )}
      </>
    );
  }

  const [videoControl, setVideoControl] = useState(props.videoControl || 'control');
  useEffect(() => {
    subscribeToCampaign((variantIndex) => {
      switch (variantIndex) {
        case 1:
          setVideoControl('v1');
          break;
      }
    }, 847);
  }, []);

  const videoTestUrl = {
    control: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/1d6d7f2af578e1a0083838c731d5a6ad',
    v1: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/b6eee61726ed024af4ce35be619e7d11'
  }[videoControl];

  const videoUrl =
    slug === 'join-discount'
      ? videoTestUrl
      : !salesFullPrice
      ? 'https://www.youtube.com/watch?v=8n4zRflU2gk'
      : 'https://www.youtube.com/watch?v=9NVl4-ZdrNk';

  useBalanceText();
  return (
    <div className="page d-flex flex-column">
      <PageMeta />
      <div className="page-content">
        <Header className="background-purple header-wrap" logoWhite style="dark" />

        <Section id="header-cta" className="section-angle-first" color="black" innerClass="text-white text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">
                  {launchContent ? (
                    <>New Year, New YOU with 14 Resolutions You Can Keep!</>
                  ) : (
                    <>Food is the foundation of health. Put it to work for you!</>
                  )}
                </h2>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Col xs="12" lg="10" className="video-col px-0 px-lg-3 mb-5">
                <HeaderVideo
                  url={videoUrl}
                  headline={
                    <h3 className="section-heading">
                      Get the 14-Day Plant-Powered Accelerator for just:{' '}
                      {!salesFullPrice ? (
                        <>
                          <del>$47</del> $7
                        </>
                      ) : (
                        <>$47</>
                      )}
                    </h3>
                  }
                  label="FDA - Sales"
                  noFixed={noFixedVideo}
                  sku="A-FDA"
                  slug={!salesFullPrice ? 'fda-special' : 'fda'}
                  price={!salesFullPrice ? '7' : '47'}
                  thumbnail={
                    slug === 'join-discount'
                      ? 'https://cdn.foodrevolution.org/fda/video-thumb/fda-videothumb-202308.gif'
                      : 'https://cdn.foodrevolution.org/fda/video-thumb/fda-video-thumbnail-sales-page-making-smoothie-computer-202201.jpg'
                  }
                  salesBtnText={btnText}
                  noButton={slug === 'join-discount' ? true : false}
                  config={{
                    stream: {
                      options: {
                        defaultTextTrack: 'en'
                      }
                    }
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                {!salesFullPrice ? (
                  <>
                    <h2 className="section-heading mb-2">
                      Get the 14-Day Plant-Powered Accelerator now for just: <del>$47</del> $7
                    </h2>
                    <h5 className="mt-0 text-400">(85% savings!)</h5>
                    {launchContent ? (
                      <h3 className="my-4">A 14-day roadmap to start off on the right foot this year</h3>
                    ) : null}
                    {ctaNoTimer ? null : (
                      <div className="cta-timer mb-3">
                        <div className="offer-box-timer-text">Your special discount expires in:</div>
                        <CountdownTimer time={salesEnd} />
                      </div>
                    )}
                    <CheckoutButton sku="A-FDA" slug="fda-special" price="7">
                      {btnText} for Only $7
                    </CheckoutButton>
                  </>
                ) : (
                  <>
                    <h2 className="section-heading mb-4">Get the 14-Day Plant-Powered Accelerator now for just: $47</h2>
                    <CheckoutButton sku="A-FDA" slug="fda" price="47">
                      {btnText}
                    </CheckoutButton>
                  </>
                )}
                <SalesDisclaimer iconColor="white" />
              </Col>
            </Row>
          </Container>
        </Section>

        {launchContent ? (
          <>
            <SectionImage
              id="fda-launch"
              color="light-gray"
              imgPosition="right"
              imgUrl={require('static/backgrounds/1347259240-1.jpg')}
            >
              <h3 className="section-heading">
                It’s a brand new year, and getting healthier is at the top of everyone’s list.
              </h3>
              <p>
                But many people will pick New Year’s resolutions they can’t stick with, and before January is over
                they’ve given up.
              </p>
              <p>
                We have just the thing to help you start this year off right — and most importantly — to keep new
                healthier habits going all year long.
              </p>
              <p>It’s simple.</p>
              <p>It’s effective.</p>
              <p>It’s fun.</p>
              <p>And best of all… for the next few days it’s only $7.</p>
              <p>
                We’ve taken the 14 most powerful steps to create lasting health and distilled them into easy, daily
                lessons that are a cinch to complete!
              </p>
            </SectionImage>
          </>
        ) : null}

        <SectionImage
          id="fda-reading"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1136640820.jpg')}
        >
          <h3 className="section-heading">Keep reading {variant === 'ad' ? null : 'if you want'} to:</h3>
          <IconList>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  Become <b>healthier</b> — no pricey <br className="d-none d-lg-block d-xl-none" />
                  supplements required.
                </>
              ) : (
                <>
                  Become a <b>healthier person</b> — no pricey supplements required.
                </>
              )}
            </IconListItem>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  <b>Have more energy,</b> in a 100% natural way that takes as little as{' '}
                  <br className="d-none d-md-block d-lg-none" />
                  five minutes a day. (Many people in their 100s swear by this!)
                </>
              ) : (
                <>
                  <b>Instantly expand</b> your energy, in a 100% natural way{' '}
                  <br className="d-none d-sm-block d-md-none d-xl-block" />
                  that takes as little as five minutes a day. (Many people{' '}
                  <br className="d-none d-sm-block d-md-none d-xl-block" />
                  in their 100s swear by this!)
                </>
              )}{' '}
            </IconListItem>
            <IconListItem>
              <b>Avoid</b> unnecessary medications, doctors’ <br className="d-none d-lg-block d-xl-none" />
              visits, and surgeries that <br className="d-none d-md-block d-lg-none" />
              take weeks or months <br className="d-none d-lg-block d-xl-none" />
              to recover from.
            </IconListItem>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  <b>Easily</b> make the switch from energy-draining foods to vibrant,{' '}
                  <br className="d-none d-sm-block d-lg-none" />
                  life-giving foods.
                </>
              ) : (
                <>
                  <b>Effortlessly</b> switch from life-zapping foods <br className="d-none d-lg-block d-xl-none" />
                  to vibrant, <br className="d-none d-sm-block d-md-none d-xl-block" />
                  life-giving foods.
                </>
              )}
            </IconListItem>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  Adopt simple <b>motivation methods</b> to pick <br className="d-none d-lg-block d-xl-none" />
                  healthy options <br className="d-none d-sm-block d-lg-none d-xl-block" />— even when hungry or{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  at a party or picnic.
                </>
              ) : (
                <>
                  Adopt iron-clad <b>motivation methods</b> so you pick healthy options{' '}
                  <br className="d-none d-md-block d-lg-none" />
                  no matter where you are or how hungry you are{' '}
                  <br className="d-none d-sm-block d-md-none d-xl-block" />— even at{' '}
                  <br className="d-none d-md-block d-lg-none" />a party or picnic.
                </>
              )}
            </IconListItem>
          </IconList>
        </SectionImage>

        <Section id="fda-necessity" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col xl="11" className="text-center">
                <h3 className="section-heading">We created this course out of necessity.</h3>
                <p>
                  After years of watching well-intentioned people attempt to completely overhaul{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  their diets only to crash <br className="d-none d-xl-block" />a few weeks later, we realized that
                  small steps taken in <br className="d-none d-lg-block d-xl-none" />
                  the right way are what lead to LASTING change.
                </p>
                <p>
                  That’s exactly what the 14-Day Plant-Powered Accelerator is designed{' '}
                  <br className="d-none d-sm-block d-lg-none" />
                  to fuel — tiny, daily actions <br className="d-none d-xl-block" />
                  that are fun, flavorful, and make <br className="d-none d-sm-block d-lg-none" />a huge impact on
                  health (deliciously)!
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col xl="10">
                <h5 className="section-heading">Discover how easy it is to:</h5>
                <div className="text-h4">
                  <ImgBulletCard img={require('static/backgrounds/1141343601.jpg')}>
                    Do a good deed for health
                  </ImgBulletCard>
                  <ImgBulletCard img={require('static/backgrounds/1241736799.jpg')}>
                    Become a positive <br className="d-none d-sm-block d-lg-none" />
                    influence on loved ones
                  </ImgBulletCard>
                  <ImgBulletCard img={require('static/backgrounds/1126508104.jpg')}>
                    Reduce animal cruelty
                  </ImgBulletCard>
                  <ImgBulletCard img={require('static/backgrounds/1278911675.jpg')}>
                    Help a struggling planet
                  </ImgBulletCard>
                </div>
                <h5 className="mt-4 text-center">...just by making a few easy choices.</h5>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="fda-testimonials-1" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Here’s What Food Revolution Network’s Members Say About Adding in More Plant-Based Whole Foods:
                </h2>
                <p className="text-center text-16 mb-0">
                  <em>
                    These testimonials reflect people’s unique experience.{' '}
                    <br className="d-none d-sm-block d-md-none" />
                    Your specific experience will vary.
                  </em>
                </p>
                <hr className="my-5" />
                <TestimonialCircle id="donna-b" />
                <hr className="my-5" />
                <TestimonialCircle id="eric-c" imgPosition="right" />
                <hr className="my-5" />
                <TestimonialCircle id="terri-c" />
                <hr className="my-5" />
                <TestimonialCircle id="margaret-s" imgPosition="right" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="fda-win"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1339027651.jpg')}
        >
          <h3 className="section-heading">
            These are just a tiny fraction of the phenomenal wins we’ve seen when people adopt a whole foods,
            plant-based lifestyle.
          </h3>
          <p>
            For over 10 years, we’ve witnessed it all: <br className="d-none d-lg-block d-xl-none" />
            tears of joy, lives renewed, relationships saved, <br className="d-none d-lg-block d-xl-none" />
            doctors astonished, total health turnarounds, <br className="d-none d-lg-block d-xl-none" />
            revived hope, new leases on life…
          </p>
          <p>
            Inside the <b>14-Day Plant-Powered Accelerator,</b> we <br className="d-none d-lg-block d-xl-none" />
            help people become energy-filled, health-conscious, compassionate, enthusiastic natural foods eaters —
            capable of outshining their former selves and shattering their old ideas of what it means to get older.
          </p>
          <p>
            What’s the deal? Why is the <b>14-Day Plant-Powered Accelerator</b> <br className="d-none d-xl-block" />
            so outstanding for people looking to jumpstart an entirely new <br className="d-none d-xl-block" />
            way of life?
          </p>
          <p>
            Because it’s so{' '}
            <b>
              <i>simple.</i>
            </b>
          </p>
        </SectionImage>

        <Section id="fda-intro" color="white">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" className="text-center">
                <p className="mb-2">INTRODUCING THE</p>
                <img
                  className="mb-3"
                  src="https://cdn.foodrevolution.org/fda/fda-logo.svg"
                  style={{maxHeight: '100px'}}
                />
                <p>
                  <i>Fast Track Your Plant-Powered Health</i>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="fda-secrets"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1210530744.jpg')}
          title={
            <h2 className="section-only-heading text-center">
              With the 14-Day Plant-Powered Accelerator, get another healthy eating secret delivered straight to your
              inbox every day!
            </h2>
          }
          titleClass="mb-3"
        >
          <IconList>
            <IconListItem>
              <b>Breathe new life</b>{' '}
              {variant === 'ad' ? 'into previously mundane kitchen routines' : 'into your kitchen routine'}.
            </IconListItem>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  Skyrocket know-how — and <b>confidence</b> that eating{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  healthier IS possible.
                </>
              ) : (
                <>
                  Skyrocket your know-how — and your <b>confidence</b>{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  that you CAN eat healthier.
                </>
              )}
            </IconListItem>
            <IconListItem>
              Cut through the nutrition confusion and get the <br className="d-none d-sm-block d-md-none d-xl-block" />
              <b>evidence-based facts.</b>
            </IconListItem>
            <IconListItem>
              <b>Delight {variant === 'ad' ? null : 'your '}family and friends</b> with new recipes.{' '}
              <br className="d-none d-sm-block d-lg-none d-xl-block" />
              (They’ll beg {variant === 'ad' ? null : 'you '}for a copy!)
            </IconListItem>
            <IconListItem>
              Amp up {variant === 'ad' ? null : 'your '}
              <b>motivation</b> by discovering WHY <br className="d-none d-sm-block d-lg-none d-xl-block" />
              certain foods are {variant === 'ad' ? 'optimal for health' : 'good for you'}.
            </IconListItem>
            <IconListItem>
              Cruise through the week with <b>more free time</b> <br className="d-none d-sm-block" />— and more energy —
              than ever.
            </IconListItem>
            <IconListItem>
              <b>Cut out food waste</b> (and that sad feeling of <br className="d-none d-sm-block d-md-none" />
              watching <br className="d-none d-md-block d-lg-none d-xl-block" />
              food go in the garbage).
            </IconListItem>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  Feel good knowing how to eat in ways <br className="d-none d-sm-block d-md-none" />
                  that <br className="d-none d-lg-block" />
                  help <b>climate change.</b>
                </>
              ) : (
                <>
                  Feel good, knowing you’re doing your part to <br className="d-none d-sm-block d-md-none d-xl-block" />
                  <b>reduce climate change.</b>
                </>
              )}
            </IconListItem>
            {variant === 'ad' ? null : (
              <IconListItem>
                <b>Fool-proof your kitchen</b> and launch <br className="d-none d-lg-block d-xl-none" />
                your new healthy lifestyle.
              </IconListItem>
            )}
            <IconListItem>
              Enjoy the short-term and long-term benefits of the planet’s most <b>nutrient-dense superfoods,</b> which
              are readily available and often <br className="d-none d-md-block d-lg-none" />
              less expensive than prepared meals!
            </IconListItem>
          </IconList>
        </SectionImage>

        <Section id="fda-cta-1" color="gradient-indigo-purple-right">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10" xl="8">
                <CtaSalesBox />
              </Col>
            </Row>

            <Row>
              <Col>
                <h4 className="text-white text-center mt-5">
                  This is {variant === 'ad' ? 'the best' : 'your'} chance to get the most cutting-edge health
                  information <i>pulled directly from scientific and medical studies</i> (NOT opinion or fads).
                </h4>
                <p className="text-white text-center">
                  <i>
                    Some of this information is so new, <br className="d-none d-sm-block d-md-none" />
                    {variant === 'ad' ? 'many doctors don’t' : 'your doctor might not'} know about it yet.
                  </i>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="fda-testimonials-2" color="light-gray">
          <Container>
            <Row>
              <Col>
                <TestimonialBubble bgColor="white" id="karen-w" />
                <hr className="my-5 border-black-30" />
                <TestimonialBubble bgColor="white" id="cheryl" />
                <hr className="my-5 border-black-30" />
                <TestimonialBubble bgColor="white" id="karen-c" />
                <hr className="my-5 border-black-30" />
                <TestimonialBubble bgColor="white" id="sara" />
                <hr className="my-5 border-black-30" />
                <TestimonialBubble bgColor="white" id="ingrid" />
                <hr className="my-5 border-black-30" />
                <TestimonialBubble bgColor="white" id="kathy-b" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="fda-modules" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center">
                  {variant === 'ad' ? (
                    <>Here’s some of what’s inside the 14-Day Plant-Powered Accelerator:</>
                  ) : (
                    <>Here’s some of what you’ll discover in the 14-Day Plant-Powered Accelerator:</>
                  )}
                </h2>
                {variant === 'ad' ? <ModuleList list={modulesAd} /> : <ModuleList />}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="fda-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox title={<>A Total 100%-Money-Back Guarantee</>}>
                  <p>
                    We’re sure you’re going to love the 14-Day Plant-Powered Accelerator,{' '}
                    <br className="d-none d-md-block d-lg-none" />
                    and that you’ll be excited to pass <br className="d-none d-xl-block" />
                    this exciting wisdom <br className="d-none d-md-block d-lg-none" />
                    on to your friends and loved ones.
                  </p>
                  <p>
                    But if for any reason whatsoever you don’t, we’ll give{' '}
                    <br className="d-none d-sm-block d-lg-none" />
                    you every penny back.
                  </p>
                  <p>
                    Plus, for every 14-Day Plant-Powered Accelerator that’s{' '}
                    <br className="d-none d-sm-block d-md-none" />
                    sold, we’ll donate to Trees for the Future so they <br className="d-none d-xl-block" />
                    can plant an organic fruit or <br className="d-none d-md-block d-lg-none" />
                    nut tree in a low-income community. Our mission at Food Revolution Network{' '}
                    <br className="d-none d-xl-block" />
                    is healthy, ethical, sustainable food for all. We care deeply about our planet and donate many
                    thousands of dollars a year to causes that make the world{' '}
                    <br className="d-none d-sm-block d-md-none" />a better place.
                  </p>
                  <p>
                    We believe that a healthier world starts when one person at a time makes a small but significant{' '}
                    <br className="d-none d-xl-block" />
                    shift in the right direction.
                  </p>
                  <p>It’s never too early (or too late) to start!</p>
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="fda-express"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1289797791.jpg')}
        >
          <h2 className="section-heading">
            The 14-Day Plant-Powered Accelerator is the “express lane” to a new and healthier life.
          </h2>
          <p>
            We can’t wait to hear about the results and wins <br className="d-none d-sm-block d-md-none d-lg-block" />
            you’ll get in just 2 weeks.
          </p>
          <p>
            So, if you want those results, jump on this <br className="d-none d-lg-block d-xl-none" />
            special offer now.
          </p>
          {!salesFullPrice ? (
            <>
              <p>
                It’s listed on our website for $47, but now’s your chance to{' '}
                <br className="d-none d-sm-block d-lg-none d-xl-block" />
                get it for less than a fancy green juice at the airport.
              </p>
              <p>
                We’re not sure how long we’ll be able to keep offering{' '}
                <br className="d-none d-sm-block d-lg-none d-xl-block" />
                the 14-Day Plant-Powered Accelerator for just $7.
              </p>
              <p>(It’s a whopping 85% off, after all.)</p>
              <p>That’s the lowest price you’ll ever see.</p>
            </>
          ) : null}
          <p>Just click the button and you’re instantly in.</p>
        </SectionImage>

        <Section id="fda-cta-3" className="section-angle-last" color="gradient-indigo-purple-right" angle="none">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10" xl="8">
                <CtaSalesBox />
              </Col>
            </Row>
          </Container>
        </Section>
      </div>

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons floating />
      </div>

      <OptinModal />
      {noExitIntent ? null : <ModalExitSales version={salesFullPrice ? 'fullPrice' : 'salesPrice'} />}
    </div>
  );
}
