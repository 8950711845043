import React, {useCallback, useState} from 'react';
import {navigate} from '@reach/router';
import classNames from 'classnames';
import ContactInfoForm from './components/ContactInfoForm';
import SegmentationForm from './components/SegmentationForm';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import './OptinForm.scss';

function OptinForm({id, linkColor = 'purple', onComplete = () => {}, onStepComplete = () => {}, ...props}) {
  const [step, setStep] = useState(0);
  return (
    <>
      {(() => {
        switch (step) {
          default:
          case 0:
            return (
              <ContactInfoForm
                onComplete={() => {
                  setStep(1);
                  onStepComplete(1);
                }}
                id={id}
              />
            );
          case 1:
            return (
              <SegmentationForm
                onComplete={() => {
                  onComplete();
                  onStepComplete(2);
                }}
                id={id}
              />
            );
        }
      })()}
    </>
  );
}
export default OptinForm;
