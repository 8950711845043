import React, {useEffect, useState} from 'react';
import {Col, Container, Input, Row} from 'reactstrap';
import {usePhaseState} from 'funnel-schedule/phase-context';
import Section, {SectionImage} from 'components/Elements/Section';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {AcceleratorSales, AcceleratorSalesFull, SalesDisclaimer} from 'components/SalesCta';
import {ImgBulletCard} from 'components/Elements/ImgBullet';
import {TestimonialCircle} from 'components/Testimonial';
import GuaranteeBox from 'components/Guarantee';
import {ModuleList} from 'components/ModuleAccordion';
import {modules, modulesAd} from 'data/module.json';

export default function SalesContent({
  ctaOverride = null,
  variant = null,
  ctaNoTimer = false,
  pageLength,
  salesSlug,
  ...props
}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  const salesFullPrice = !ctaOverride || ctaOverride === 'fullPrice';
  const salesEnd = new Date(defaultSchedule.salesEnd).getTime();

  function CtaSalesBox(props) {
    return (
      <>
        {ctaOverride ? (
          <>
            {ctaOverride === 'salesPrice' ? (
              <>
                {ctaNoTimer ? (
                  <AcceleratorSales slug={salesSlug} />
                ) : (
                  <AcceleratorSales slug={salesSlug} date={salesEnd} timerText="Your discount expires in:" />
                )}
              </>
            ) : (
              <AcceleratorSalesFull />
            )}
          </>
        ) : (
          <AcceleratorSalesFull />
        )}
      </>
    );
  }

  return (
    <>
      {pageLength === 'control' ? (
        <>
          <SectionImage
            id="fda-reading"
            color="white"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1136640820.jpg')}
          >
            <h3 className="section-heading">Keep reading {variant === 'ad' ? null : 'if you want'} to:</h3>
            <IconList>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Become <b>healthier</b> — no pricey <br className="d-none d-lg-block d-xl-none" />
                    supplements required.
                  </>
                ) : (
                  <>
                    Become a <b>healthier person</b> — no pricey supplements required.
                  </>
                )}
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    <b>Have more energy,</b> in a 100% natural way that takes as little as{' '}
                    <br className="d-none d-md-block d-lg-none" />
                    five minutes a day. (Many people in their 100s swear by this!)
                  </>
                ) : (
                  <>
                    <b>Instantly expand</b> your energy, in a 100% natural way{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />
                    that takes as little as five minutes a day. (Many people{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />
                    in their 100s swear by this!)
                  </>
                )}{' '}
              </IconListItem>
              <IconListItem>
                <b>Avoid</b> unnecessary medications, doctors’ <br className="d-none d-lg-block d-xl-none" />
                visits, and surgeries that <br className="d-none d-md-block d-lg-none" />
                take weeks or months <br className="d-none d-lg-block d-xl-none" />
                to recover from.
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    <b>Easily</b> make the switch from energy-draining foods to vibrant,{' '}
                    <br className="d-none d-sm-block d-lg-none" />
                    life-giving foods.
                  </>
                ) : (
                  <>
                    <b>Effortlessly</b> switch from life-zapping foods <br className="d-none d-lg-block d-xl-none" />
                    to vibrant, <br className="d-none d-sm-block d-md-none d-xl-block" />
                    life-giving foods.
                  </>
                )}
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Adopt simple <b>motivation methods</b> to pick <br className="d-none d-lg-block d-xl-none" />
                    healthy options <br className="d-none d-sm-block d-lg-none d-xl-block" />— even when hungry or{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    at a party or picnic.
                  </>
                ) : (
                  <>
                    Adopt iron-clad <b>motivation methods</b> so you pick healthy options{' '}
                    <br className="d-none d-md-block d-lg-none" />
                    no matter where you are or how hungry you are{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />— even at{' '}
                    <br className="d-none d-md-block d-lg-none" />a party or picnic.
                  </>
                )}
              </IconListItem>
            </IconList>
          </SectionImage>
          <SectionImage
            id="fda-food-medicine"
            color="light-gray"
            imgPosition="right"
            imgUrl={require('static/backgrounds/1209185763.jpg')}
          >
            <h2 className="section-heading mb-3">
              We’ve all heard the saying, <br className="d-none d-sm-block" />
              “Food is medicine.”
            </h2>
            <h3 className="mt-0">
              {variant === 'ad' ? (
                'But which are the right ones?'
              ) : (
                <>
                  But are you eating <br className="d-none d-lg-block d-xl-none" />
                  the right ones?
                </>
              )}
            </h3>
            <p>
              {variant === 'ad' ? 'There are countless' : 'Perhaps you’ve heard'} stories of people{' '}
              <br className="d-none d-lg-block d-xl-none" />
              who’ve miraculously turned <br className="d-none d-md-block d-lg-none" />
              their lives around <br className="d-none d-lg-block d-xl-none" />
              (even shocking their doctors) by cutting out <br className="d-none d-lg-block d-xl-none" />
              certain <br className="d-none d-md-block d-lg-none" />
              foods or adding in new ones.
            </p>
            <p>
              But when it comes time to grocery shop or cook, <br className="d-none d-lg-block d-xl-none" />
              it’s not always easy.
            </p>
            <p>
              {variant === 'ad' ? (
                <>
                  There’s conflicting articles and advice on the web, <br className="d-none d-lg-block d-xl-none" />
                  some of it outdated <br className="d-none d-md-block d-lg-none" />
                  and definitely NOT written by credible scientists or nutritionists.
                </>
              ) : (
                <>
                  You’ll find conflicting articles and advice on the web, <br className="d-none d-lg-block" />
                  some of it outdated <br className="d-none d-md-block d-lg-none" />
                  and definitely NOT written by credible <br className="d-none d-xl-block" />
                  scientists or nutritionists.
                </>
              )}
            </p>
            <p>
              Fads and slick marketing can trip {variant === 'ad' ? 'anyone' : 'you'} up with{' '}
              <br className="d-none d-lg-block d-xl-none" />
              the latest new miracle <br className="d-none d-md-block d-lg-none" />
              superfood or some other unfounded suggestion.
            </p>
            <p>
              {variant === 'ad' ? (
                <>
                  Many people know exactly what they <i>should</i> be doing, but have{' '}
                  <br className="d-none d-xl-block" />
                  a hard time doing it. Some are experiencing health issues that <br className="d-none d-xl-block" />
                  make them feel older. <br className="d-none d-md-block d-lg-none" />
                  Some have even received concerning <br className="d-none d-xl-block" />
                  news from their doctor.
                </>
              ) : (
                <>
                  Or, maybe you know what you <i>should</i> do, but you’ve fallen off the wagon. Maybe you are
                  experiencing some health issues that make <br className="d-none d-sm-block d-md-none" />
                  you feel older <br className="d-none d-md-block d-lg-none" />
                  than you really are. Maybe you’ve even gotten some concerning news{' '}
                  <br className="d-none d-md-block d-lg-none" />
                  from a doctor.
                </>
              )}
            </p>
          </SectionImage>
          <SectionImage
            id="fda-truth"
            color="white"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1140366488.jpg')}
          >
            <h3 className="section-heading">
              {variant === 'ad' ? (
                <>
                  The truth is that without <br className="d-none d-lg-block d-xl-none" />
                  the right <br className="d-none d-md-block d-lg-none" />
                  nutrient-rich foods, <br className="d-none d-lg-block d-xl-none" />
                  it’s hard to thrive <br className="d-none d-sm-block d-lg-none d-xl-block" />— and it’s{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  hard to live as long.
                </>
              ) : (
                <>
                  The truth is that if you’re not <br className="d-none d-sm-block d-md-none" />
                  eating the right nutrient-rich foods, <br className="d-none d-sm-block d-md-none" />
                  you won’t feel as good <br className="d-none d-md-block d-xl-none" />— and you might not live as long.
                </>
              )}
            </h3>
            <p>
              {variant === 'ad' ? (
                <>
                  Caring a lot about health isn’t enough, and <br className="d-none d-lg-block d-xl-none" />
                  it isn’t <br className="d-none d-xl-block" />
                  always <br className="d-none d-sm-block d-md-none" />
                  easy to eat right.
                </>
              ) : (
                <>
                  Many people know exactly what they should be doing, but have <br className="d-none d-xl-block" />a
                  hard time doing it. Some are experiencing health issues that <br className="d-none d-xl-block" />
                  make them feel older. Some have even received concerning <br className="d-none d-xl-block" />
                  news from their doctor.
                </>
              )}
            </p>
            <p>
              {variant === 'ad' ? (
                <>
                  Sadly, this keeps so many people blocked from the wonderful way life is supposed to feel. In other
                  words, people miss out on the fun things they want to do in their free time. It can be difficult to be
                  there for loved ones, or to stay productive at work.
                </>
              ) : (
                <>
                  Sadly, this keeps you blocked from the wonderful way life is supposed to feel. In other words, you
                  miss out on the fun things you want to do in your free time. It can be difficult to be there for your
                  loved ones, or to stay productive at work.
                </>
              )}
            </p>
            <p>
              {variant === 'ad' ? (
                <>
                  And the reality is that we’ve all had setbacks <br className="d-none d-lg-block d-xl-none" />
                  while striving <br className="d-none d-sm-block d-md-none d-xl-block" />
                  to be healthier.{' '}
                </>
              ) : (
                <>
                  That’s not your fault, and there’s no need to be ashamed. We’ve all had setbacks while striving{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  to be healthier.
                </>
              )}
            </p>
            <h4 className="mt-0">
              To make lasting change, we need to <br className="d-none d-sm-block d-lg-none" />
              think differently about food.
            </h4>
          </SectionImage>
          <SectionImage
            id="fda-science"
            color="light-gray"
            imgPosition="right"
            imgUrl={require('static/backgrounds/1302437997.jpg')}
          >
            <h3 className="mt-3">
              {variant === 'ad' ? (
                <>
                  Thousands of peer-reviewed studies <br className="d-none d-md-block d-lg-none" />
                  show the food we eat <br className="d-none d-lg-block d-xl-none" />
                  is absolutely the <br className="d-none d-md-block d-lg-none" />
                  best thing <br className="d-none d-lg-block d-xl-none" />
                  for health.
                </>
              ) : (
                <>
                  Thousands of peer-reviewed studies <br className="d-none d-md-block d-lg-none" />
                  show that what you eat <br className="d-none d-lg-block d-xl-none" />
                  is the best thing <br className="d-none d-md-block d-lg-none" />
                  you can do <br className="d-none d-lg-block d-xl-none" />
                  for your health.
                </>
              )}
            </h3>
            <IconList iconColor="purple">
              <IconListItem icon={faArrowRight}>
                Leafy greens are shown to protect against some of the{' '}
                <br className="d-none d-sm-block d-lg-none d-xl-block" />
                most common health problems.<sup>[1–6]</sup>
              </IconListItem>
              <IconListItem icon={faArrowRight}>
                Nutrients in citrus are shown to help keep <br className="d-none d-lg-block d-xl-none" />
                immune function <br className="d-none d-sm-block d-md-none d-xl-block" />
                and blood flow healthy, <br className="d-none d-lg-block d-xl-none" />
                and support a healthy heart heart <br className="d-none d-sm-block d-md-none d-xl-block" />
                and <br className="d-none d-lg-block d-xl-none" />
                healthy vision.
                <sup>[7–10]</sup>
              </IconListItem>
              <IconListItem icon={faArrowRight}>
                Eating lower amounts of omega-6 fatty acids <br className="d-none d-lg-block d-xl-none" />
                and higher amounts of omega-3 fatty acids helps to maintain a healthy cardiovascular system, respiratory
                system, endocrine <br className="d-none d-sm-block d-md-none" />
                system, <br className="d-none d-lg-block d-xl-none" />
                and more.<sup>[11–14]</sup>
              </IconListItem>
              <IconListItem icon={faArrowRight}>
                Even a tablespoon of shredded raw <br className="d-none d-lg-block d-xl-none" />
                cabbage provides <br className="d-none d-sm-block d-md-none" />
                potent enzymes and <br className="d-none d-lg-block d-xl-none" />
                precious sulforaphane needed to <br className="d-none d-sm-block d-md-none" />
                maintain <br className="d-none d-lg-block" />
                brain health.<sup>[15,16]</sup>
              </IconListItem>
            </IconList>
          </SectionImage>
          <Section id="fda-facts-1" color="white">
            <Container>
              <Row className="justify-content-center">
                <Col xl="10">
                  <h3 className="section-heading text-center">
                    {variant === 'ad' ? (
                      <>
                        Knowing what to eat — and how <br className="d-none d-sm-block d-md-none" />
                        to make it <br className="d-none d-lg-block" />
                        delicious — provides <br className="d-none d-sm-block d-md-none" />
                        endless health rewards.
                      </>
                    ) : (
                      <>
                        When you know what to eat — and <br className="d-none d-sm-block d-md-none" />
                        how to make <br className="d-none d-xl-block" />
                        it delicious — your <br className="d-none d-sm-block d-md-none" />
                        body will reward you.
                      </>
                    )}
                  </h3>
                  <div className="text-h4">
                    <ImgBulletCard img={require('static/backgrounds/1293545906.jpg')}>Abundant energy</ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1217582444.jpg')}>Blissful sleep</ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1165636272.jpg')}>A sharp mind</ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1125619200.jpg')}>
                      Massive productivity
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1285124183.jpg')}>Deep joy</ImgBulletCard>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
          <SectionImage
            id="fda-facts-2"
            color="light-gray"
            imgPosition="right"
            imgUrl={require('static/backgrounds/965148388.jpg')}
          >
            <Container>
              <Row className="justify-content-center">
                <Col xl="10">
                  <p>
                    {variant === 'ad' ? (
                      <>
                        PLUS (and this is huge), it turns out that the{' '}
                        <b>
                          <i>best foods for us to eat</i>
                        </b>{' '}
                        are also the same ones that{' '}
                        <b>
                          <i>don’t poison our rivers, air, and soil.</i>
                        </b>
                      </>
                    ) : (
                      <>
                        Plus, you’ll be doing the planet a HUGE favor — because it turns out that favor — because it
                        turns out that{' '}
                        <b>
                          <i>the best foods for you</i>
                        </b>{' '}
                        are also the same ones that
                        <b>
                          <i>don’t don’t poison our rivers, air, and soil.</i>
                        </b>
                      </>
                    )}
                  </p>
                  <p>
                    Yes, it’s sad but true. The modern meat and dairy industries are huge contributors to{' '}
                    <b>climate change.</b>
                  </p>
                  <p>
                    Industrialized livestock is responsible for <br className="d-none d-lg-block d-xl-none" />
                    even more <b>greenhouse gas emissions</b> than <br className="d-none d-lg-block d-xl-none" />
                    all of the world’s cars, planes, trucks, ships, <br className="d-none d-lg-block d-xl-none" />
                    trains, and buses combined!
                  </p>
                  <h4 className="mt-0 mb-3">
                    Eating more veggies, fruits, and whole foods <br className="d-none d-md-block d-lg-none" />
                    is the answer.
                  </h4>
                  <p>Learn how to do it right and make it stick!</p>
                </Col>
              </Row>
            </Container>
          </SectionImage>
          <Section id="fda-testimonials-1" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-uppercase text-center">
                    Here’s What <br className="d-none d-sm-block d-md-none" />
                    Food Revolution Network’s Members Say About Adding in More <br className="d-none d-xl-block" />
                    Plant-Based <br className="d-none d-sm-block d-md-none" />
                    Whole Foods:
                  </h2>
                  <p className="text-center text-16 mb-0">
                    <em>
                      These testimonials reflect people’s unique experience.{' '}
                      <br className="d-none d-sm-block d-md-none" />
                      Your specific experience will vary.
                    </em>
                  </p>
                  <hr className="my-5" />
                  <TestimonialCircle id="donna-b" />
                  <hr className="my-5" />
                  <TestimonialCircle id="eric-c" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="terri-c" />
                  <hr className="my-5" />
                  <TestimonialCircle id="margaret-s" imgPosition="right" />
                </Col>
              </Row>
            </Container>
          </Section>
          <SectionImage
            id="fda-win"
            color="light-gray"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1339027651.jpg')}
          >
            <h3 className="section-heading">
              These are just a tiny fraction <br className="d-none d-sm-block d-md-none" />
              of <br className="d-none d-xl-block" />
              the phenomenal wins we’ve seen <br className="d-none d-xl-block" />
              when people adopt a whole foods, <br className="d-none d-xl-block" />
              plant-based lifestyle.
            </h3>
            <p>
              For over 10 years, we’ve witnessed it all: <br className="d-none d-lg-block d-xl-none" />
              tears of joy, lives renewed, relationships saved, <br className="d-none d-lg-block d-xl-none" />
              doctors astonished, total health turnarounds, <br className="d-none d-lg-block d-xl-none" />
              revived hope, new leases on life…
            </p>
            <p>
              Inside the <b>14-Day Plant-Powered Accelerator,</b> we <br className="d-none d-lg-block d-xl-none" />
              help people become energy-filled, health-conscious, compassionate, enthusiastic natural foods eaters —
              capable of outshining their former selves and shattering their old ideas of what it means to get older.
            </p>
            <p>
              What’s the deal? Why is the <b>14-Day Plant-Powered Accelerator</b> <br className="d-none d-xl-block" />
              so outstanding for people looking to jumpstart an entirely new <br className="d-none d-xl-block" />
              way of life?
            </p>
            <p>
              Because it’s so{' '}
              <b>
                <i>simple.</i>
              </b>
            </p>
          </SectionImage>
          <Section id="fda-intro" color="white">
            <Container>
              <Row className="justify-content-center">
                <Col lg="10" className="text-center">
                  <p className="mb-2">INTRODUCING THE</p>
                  <img
                    className="mb-3"
                    src="https://cdn.foodrevolution.org/fda/fda-logo.svg"
                    style={{maxHeight: '100px'}}
                  />
                  <p>
                    <i>Fast Track Your Plant-Powered Health</i>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>
          <SectionImage
            id="fda-secrets"
            color="white"
            imgPosition="right"
            imgUrl={require('static/backgrounds/1210530744.jpg')}
            title={
              <h2 className="section-only-heading text-center">
                With the 14-Day Plant-Powered Accelerator, <br className="d-none d-xl-block" />
                get another healthy eating secret delivered <br className="d-none d-xl-block" />
                straight <br className="d-none d-sm-block d-lg-none" />
                to your inbox every day!
              </h2>
            }
            titleClass="mb-3"
          >
            <IconList>
              <IconListItem>
                <b>Breathe new life</b>{' '}
                {variant === 'ad' ? 'into previously mundane kitchen routines' : 'into your kitchen routine'}.
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Skyrocket know-how — and <b>confidence</b> that eating{' '}
                    <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    healthier IS possible.
                  </>
                ) : (
                  <>
                    Skyrocket your know-how — and your <b>confidence</b>{' '}
                    <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    that you CAN eat healthier.
                  </>
                )}
              </IconListItem>
              <IconListItem>
                Cut through the nutrition confusion and get the{' '}
                <br className="d-none d-sm-block d-md-none d-xl-block" />
                <b>evidence-based facts.</b>
              </IconListItem>
              <IconListItem>
                <b>Delight {variant === 'ad' ? null : 'your '}family and friends</b> with new recipes.{' '}
                <br className="d-none d-sm-block d-lg-none d-xl-block" />
                (They’ll beg {variant === 'ad' ? null : 'you '}for a copy!)
              </IconListItem>
              <IconListItem>
                Amp up {variant === 'ad' ? null : 'your '}
                <b>motivation</b> by discovering WHY <br className="d-none d-sm-block d-lg-none d-xl-block" />
                certain foods are {variant === 'ad' ? 'optimal for health' : 'good for you'}.
              </IconListItem>
              <IconListItem>
                Cruise through the week with <b>more free time</b> <br className="d-none d-sm-block" />— and more energy
                — than ever.
              </IconListItem>
              <IconListItem>
                <b>Cut out food waste</b> (and that sad feeling of <br className="d-none d-sm-block d-md-none" />
                watching <br className="d-none d-md-block d-lg-none d-xl-block" />
                food go in the garbage).
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Feel good knowing how to eat in ways <br className="d-none d-sm-block d-md-none" />
                    that <br className="d-none d-lg-block" />
                    help <b>climate change.</b>
                  </>
                ) : (
                  <>
                    Feel good, knowing you’re doing your part to{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />
                    <b>reduce climate change.</b>
                  </>
                )}
              </IconListItem>
              {variant === 'ad' ? null : (
                <IconListItem>
                  <b>Fool-proof your kitchen</b> and launch <br className="d-none d-lg-block d-xl-none" />
                  your new healthy lifestyle.
                </IconListItem>
              )}
              <IconListItem>
                Enjoy the short-term and long-term benefits of the planet’s most <b>nutrient-dense superfoods,</b> which
                are readily available and often <br className="d-none d-md-block d-lg-none" />
                less expensive than prepared meals!
              </IconListItem>
            </IconList>
          </SectionImage>
          <Section id="fda-cta-1" color="gradient-indigo-purple-right">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10" xl="8">
                  <CtaSalesBox />
                </Col>
              </Row>
            </Container>
          </Section>
          <Section id="fda-necessity" color="light-gray">
            <Container>
              <Row className="justify-content-center">
                <Col xl="11" className="text-center">
                  <h3 className="section-heading">We created this course out of necessity.</h3>
                  <p>
                    After years of watching well-intentioned people attempt to completely overhaul{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    their diets only to crash <br className="d-none d-xl-block" />a few weeks later, we realized that
                    small steps taken in <br className="d-none d-lg-block d-xl-none" />
                    the right way are what lead to LASTING change.
                  </p>
                  <p>
                    That’s exactly what the 14-Day Plant-Powered Accelerator is designed{' '}
                    <br className="d-none d-sm-block d-lg-none" />
                    to fuel — tiny, daily actions <br className="d-none d-xl-block" />
                    that are fun, flavorful, and make <br className="d-none d-sm-block d-lg-none" />a huge impact on
                    health (deliciously)!
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xl="10">
                  <h5 className="section-heading">Discover how easy it is to:</h5>
                  <div className="text-h4">
                    <ImgBulletCard img={require('static/backgrounds/1141343601.jpg')}>
                      Do a good deed for health
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1241736799.jpg')}>
                      Become a positive <br className="d-none d-sm-block d-lg-none" />
                      influence on loved ones
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1126508104.jpg')}>
                      Reduce animal cruelty
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1278911675.jpg')}>
                      Help a struggling planet
                    </ImgBulletCard>
                  </div>
                  <h5 className="mt-4 text-center">...just by making a few easy choices.</h5>
                </Col>
              </Row>
            </Container>
          </Section>
          <Section id="fda-modules" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center">
                    {variant === 'ad' ? (
                      <>
                        Here’s some of <br className="d-none d-sm-block d-md-none" />
                        what’s inside the <br className="d-none d-md-block" />
                        14-Day <br className="d-none d-sm-block d-md-none" />
                        Plant-Powered Accelerator:
                      </>
                    ) : (
                      <>
                        Here’s some of what <br className="d-none d-sm-block d-lg-none" />
                        you’ll discover in the <br className="d-none d-lg-block" />
                        14-Day <br className="d-none d-sm-block d-lg-none" />
                        Plant-Powered Accelerator:
                      </>
                    )}
                  </h2>
                  {variant === 'ad' ? <ModuleList list={modulesAd} /> : <ModuleList />}
                </Col>
              </Row>
            </Container>
          </Section>
          <Section id="fda-cta-2" color="gradient-indigo-purple-right">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10" xl="8">
                  <CtaSalesBox />
                </Col>
              </Row>
            </Container>
          </Section>
          <Section id="fda-course" color="light-gray">
            <Container>
              <Row>
                <Col className="text-center">
                  <h2 className="mt-0">
                    {variant === 'ad' ? (
                      <>
                        The 14-Day Plant-Powered <br className="d-none d-lg-block d-xl-none" />
                        Accelerator is designed specifically <br className="d-none d-lg-block d-xl-none" />
                        to make it <br className="d-none d-sm-block d-md-none" />
                        easy to succeed.
                      </>
                    ) : (
                      <>The 14-Day Plant-Powered Accelerator is designed specifically to help you succeed.</>
                    )}
                  </h2>
                  <p>There’s nothing to log into, no passwords to remember.</p>
                  <p>
                    Each day for 14 days, you’ll get an in-depth email <br className="d-none d-sm-block d-lg-none" />
                    with that day’s lesson, action step, and recipe.
                  </p>
                  <p>{variant === 'ad' ? 'Go' : 'You can go'} as quickly or as deeply as you want…</p>
                </Col>
              </Row>
              <Row className="justify-content-center mt-4">
                <Col xl="10">
                  <ImgBulletCard img={require('static/backgrounds/1248697352.jpg')}>
                    With links to dozens of our other health articles <br className="d-none d-sm-block" />
                    (some viewed over 500,000 times)
                  </ImgBulletCard>
                  <ImgBulletCard img={require('static/backgrounds/1249730776.jpg')}>
                    Our most popular plant-based recipes
                  </ImgBulletCard>
                  <ImgBulletCard img={require('static/backgrounds/1178621023.jpg')}>
                    And even links to published research <br className="d-none d-sm-block d-md-none" />
                    journal articles in case <br className="d-none d-lg-block" />
                    you want to do your own <br className="d-none d-sm-block d-lg-none" />
                    fact-checking or deep-diving
                  </ImgBulletCard>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl="10" className="text-center">
                  <h4 className="mt-4">
                    This is {variant === 'ad' ? 'the best' : 'your'} chance to get the{' '}
                    <br className="d-none d-sm-block d-md-none" />
                    most cutting-edge <br className="d-none d-lg-block" />
                    health information <br className="d-none d-sm-block d-md-none" />
                    <i>
                      pulled directly from scientific <br className="d-none d-lg-block" />
                      and medical studies
                    </i>{' '}
                    (NOT opinion or fads).
                  </h4>
                  <p>
                    <i>
                      Some of this information is so new, <br className="d-none d-sm-block d-md-none" />
                      {variant === 'ad' ? 'many doctors don’t' : 'your doctor might not'} know about it yet.
                    </i>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>
          <Section id="fda-testimonials-2" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-uppercase text-center">
                    What Food Revolution Network’s <br className="d-none d-xl-block" />
                    Members Say <br className="d-none d-md-block d-lg-none" />
                    About Eating a <br className="d-none d-xl-block" />
                    Whole Foods, <br className="d-none d-md-block d-lg-none" />
                    Plant-Based Lifestyle:
                  </h2>
                  <p className="text-center text-16 mb-0">
                    <em>
                      These testimonials reflect people’s unique experience.{' '}
                      <br className="d-none d-sm-block d-md-none" />
                      Your specific experience will vary.
                    </em>
                  </p>
                  <hr className="my-5" />
                  <TestimonialCircle id="linda-w" />
                  <hr className="my-5" />
                  <TestimonialCircle id="ingrid-s" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="cecile-b" />
                </Col>
              </Row>
            </Container>
          </Section>
          <Section id="fda-guarantee" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <GuaranteeBox title={<>A Total 100%-Money-Back Guarantee</>}>
                    <p>
                      We’re sure you’re going to love the 14-Day Plant-Powered Accelerator,{' '}
                      <br className="d-none d-md-block d-lg-none" />
                      and that you’ll be excited to pass <br className="d-none d-xl-block" />
                      this exciting wisdom <br className="d-none d-md-block d-lg-none" />
                      on to your friends and loved ones.
                    </p>
                    <p>
                      But if for any reason whatsoever you don’t, we’ll give{' '}
                      <br className="d-none d-sm-block d-lg-none" />
                      you every penny back.
                    </p>
                    <p>
                      Plus, for every 14-Day Plant-Powered Accelerator that’s{' '}
                      <br className="d-none d-sm-block d-md-none" />
                      sold, we’ll donate to Trees for the Future so they <br className="d-none d-xl-block" />
                      can plant an organic fruit or <br className="d-none d-md-block d-lg-none" />
                      nut tree in a low-income community. Our mission at Food Revolution Network{' '}
                      <br className="d-none d-xl-block" />
                      is healthy, ethical, sustainable food for all. We care deeply about our planet and donate many
                      thousands of dollars a year to causes that make the world{' '}
                      <br className="d-none d-sm-block d-md-none" />a better place.
                    </p>
                    <p>
                      We believe that a healthier world starts when one person at a time makes a small but significant{' '}
                      <br className="d-none d-xl-block" />
                      shift in the right direction.
                    </p>
                    <p>It’s never too early (or too late) to start!</p>
                  </GuaranteeBox>
                </Col>
              </Row>
            </Container>
          </Section>
          <SectionImage
            id="fda-express"
            color="white"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1289797791.jpg')}
          >
            <h2 className="section-heading">
              The 14-Day Plant-Powered Accelerator is the “express lane” <br className="d-none d-sm-block d-lg-none" />
              to a new and healthier life.
            </h2>
            <p>
              We can’t wait to hear about the results and wins <br className="d-none d-sm-block d-md-none d-lg-block" />
              you’ll get in just 2 weeks.
            </p>
            <p>
              So, if you want those results, jump on this <br className="d-none d-lg-block d-xl-none" />
              special offer now.
            </p>
            {!salesFullPrice ? (
              <>
                <p>
                  It’s listed on our website for $47, but now’s your chance to{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  get it for less than a fancy green juice at the airport.
                </p>
                <p>
                  We’re not sure how long we’ll be able to keep offering{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  the 14-Day Plant-Powered Accelerator for just $7.
                </p>
                <p>(It’s a whopping 85% off, after all.)</p>
                <p>That’s the lowest price you’ll ever see.</p>
              </>
            ) : null}
            <p>Just click the button and you’re instantly in.</p>
          </SectionImage>
          <Section id="fda-cta-3" color="gradient-indigo-purple-right">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10" xl="8">
                  <CtaSalesBox />
                </Col>
              </Row>
            </Container>
          </Section>
          <Section id="fda-references" className="section-angle-last" color="light-gray" angle="none">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col>
                  <h3 className="section-heading">References</h3>
                  <ul className="list-references list-group text-small mb-0">
                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[1]</div>
                      <div>
                        Pollock RL. The effect of green leafy and cruciferous vegetable intake on the incidence of
                        cardiovascular disease: A meta-analysis. <i>JRSM Cardiovasc Dis.</i> 2016;5:2048004016661435.
                        doi:10.1177/2048004016661435
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[2]</div>
                      <div>
                        Yang Y, Huang CY, Peng SS, Li J. Carotenoid analysis of several dark-green leafy vegetables
                        associated with a lower risk of cancers. <i>Biomed Environ Sci.</i> 1996;9(4):386-392.
                        https://pubmed.ncbi.nlm.nih.gov/8988807/
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[3]</div>
                      <div>
                        Wang PY, Fang JC, Gao ZH, Zhang C, Xie SY. Higher intake of fruits, vegetables or their fiber
                        reduces the risk of type 2 diabetes: A meta-analysis. <i>J Diabetes Investig.</i>{' '}
                        2016;7(1):56-69. doi:10.1111/jdi.12376
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[4]</div>
                      <div>
                        Mangano KM, Noel SE, Lai CQ, et al. Diet-derived fruit and vegetable metabolites show
                        sex-specific inverse relationships to osteoporosis status. <i>Bone.</i> 2021;144:115780.
                        doi:10.1016/j.bone.2020.115780
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[5]</div>
                      <div>
                        Price CT, Langford JR, Liporace FA. Essential Nutrients for Bone Health and a Review of their
                        Availability in the Average North American Diet. <i>Open Orthop J.</i> 2012;6:143-149.
                        doi:10.2174/1874325001206010143
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[6]</div>
                      <div>
                        Morris MC, Wang Y, Barnes LL, Bennett DA, Dawson-Hughes B, Booth SL. Nutrients and bioactives in
                        green leafy vegetables and cognitive decline: Prospective study. <i>Neurology.</i>{' '}
                        2018;90(3):e214-e222. doi:10.1212/WNL.0000000000004815
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[7]</div>
                      <div>
                        Assini JM, Mulvihill EE, Huff MW. Citrus flavonoids and lipid metabolism.{' '}
                        <i>Curr Opin Lipidol.</i> 2013;24(1):34-40. doi:10.1097/MOL.0b013e32835c07fd
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[8]</div>
                      <div>
                        Nauman MC, Johnson JJ. Clinical application of bergamot (Citrus bergamia) for reducing high
                        cholesterol and cardiovascular disease markers. Integr Food Nutr Metab.
                        2019;6(2):10.15761/IFNM.1000249. doi:10.15761/IFNM.1000249
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[9]</div>
                      <div>
                        Carr AC, Maggini S. Vitamin C and Immune Function. Nutrients. 2017;9(11):1211.
                        doi:10.3390/nu9111211
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[10]</div>
                      <div>
                        Rasmussen HM, Johnson EJ. Nutrients for the aging eye. <i>Clin Interv Aging.</i> 2013;8:741-748.
                        doi:10.2147/CIA.S45399
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[11]</div>
                      <div>
                        Vargas ML, Almario RU, Buchan W, Kim K, Karakas SE. Metabolic and endocrine effects of
                        long-chain versus essential omega-3 polyunsaturated fatty acids in polycystic ovary syndrome.{' '}
                        <i>Metabolism.</i> 2011;60(12):1711-1718. doi:10.1016/j.metabol.2011.04.007
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[12]</div>
                      <div>
                        Doaei S, Gholami S, Rastgoo S, et al. The effect of omega-3 fatty acid supplementation on
                        clinical and biochemical parameters of critically ill patients with COVID-19: a randomized
                        clinical trial. <i>J Transl Med.</i> 2021;19(1):128. doi:10.1186/s12967-021-02795-5
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[13]</div>
                      <div>
                        Jain AP, Aggarwal KK, Zhang PY. Omega-3 fatty acids and cardiovascular disease.{' '}
                        <i>Eur Rev Med Pharmacol Sci.</i> 2015;19(3):441-445. https://pubmed.ncbi.nlm.nih.gov/25720716/
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[14]</div>
                      <div>
                        Gutiérrez S, Svahn SL, Johansson ME. Effects of Omega-3 Fatty Acids on Immune Cells.{' '}
                        <i>Int J Mol Sci.</i> 2019;20(20):5028. doi:10.3390/ijms20205028
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[15]</div>
                      <div>
                        Tortorella SM, Royce SG, Licciardi PV, Karagiannis TC. Dietary Sulforaphane in Cancer
                        Chemoprevention: The Role of Epigenetic Regulation and HDAC Inhibition.{' '}
                        <i>Antioxid Redox Signal.</i> 2015;22(16):1382-1424. doi:10.1089/ars.2014.6097
                      </div>
                    </li>

                    <li className="list-group-item reference-item d-flex align-items-center p-3">
                      <div className="reference-number">[16]</div>
                      <div>
                        Sun Y, Yang T, Mao L, Zhang F. Sulforaphane Protects against Brain Diseases: Roles of
                        Cytoprotective Enzymes. <i>Austin J Cerebrovasc Dis Stroke.</i> 2017;4(1):1054.
                        doi:10.26420/austinjcerebrovascdisstroke.2017.1054
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Section>{' '}
        </>
      ) : null}

      {pageLength === 'versionOne' ? (
        <>
          <SectionImage
            id="fda-reading"
            color="white"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1136640820.jpg')}
          >
            <h3 className="section-heading">Keep reading {variant === 'ad' ? null : 'if you want'} to:</h3>
            <IconList>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Become <b>healthier</b> — no pricey <br className="d-none d-lg-block d-xl-none" />
                    supplements required.
                  </>
                ) : (
                  <>
                    Become a <b>healthier person</b> — no pricey supplements required.
                  </>
                )}
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    <b>Have more energy,</b> in a 100% natural way that takes as little as{' '}
                    <br className="d-none d-md-block d-lg-none" />
                    five minutes a day. (Many people in their 100s swear by this!)
                  </>
                ) : (
                  <>
                    <b>Instantly expand</b> your energy, in a 100% natural way{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />
                    that takes as little as five minutes a day. (Many people{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />
                    in their 100s swear by this!)
                  </>
                )}{' '}
              </IconListItem>
              <IconListItem>
                <b>Avoid</b> unnecessary medications, doctors’ <br className="d-none d-lg-block d-xl-none" />
                visits, and surgeries that <br className="d-none d-md-block d-lg-none" />
                take weeks or months <br className="d-none d-lg-block d-xl-none" />
                to recover from.
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    <b>Easily</b> make the switch from energy-draining foods to vibrant,{' '}
                    <br className="d-none d-sm-block d-lg-none" />
                    life-giving foods.
                  </>
                ) : (
                  <>
                    <b>Effortlessly</b> switch from life-zapping foods <br className="d-none d-lg-block d-xl-none" />
                    to vibrant, <br className="d-none d-sm-block d-md-none d-xl-block" />
                    life-giving foods.
                  </>
                )}
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Adopt simple <b>motivation methods</b> to pick <br className="d-none d-lg-block d-xl-none" />
                    healthy options <br className="d-none d-sm-block d-lg-none d-xl-block" />— even when hungry or{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    at a party or picnic.
                  </>
                ) : (
                  <>
                    Adopt iron-clad <b>motivation methods</b> so you pick healthy options{' '}
                    <br className="d-none d-md-block d-lg-none" />
                    no matter where you are or how hungry you are{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />— even at{' '}
                    <br className="d-none d-md-block d-lg-none" />a party or picnic.
                  </>
                )}
              </IconListItem>
            </IconList>
          </SectionImage>

          <Section id="fda-necessity" color="light-gray">
            <Container>
              <Row className="justify-content-center">
                <Col xl="11" className="text-center">
                  <h3 className="section-heading">We created this course out of necessity.</h3>
                  <p>
                    After years of watching well-intentioned people attempt to completely overhaul{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    their diets only to crash <br className="d-none d-xl-block" />a few weeks later, we realized that
                    small steps taken in <br className="d-none d-lg-block d-xl-none" />
                    the right way are what lead to LASTING change.
                  </p>
                  <p>
                    That’s exactly what the 14-Day Plant-Powered Accelerator is designed{' '}
                    <br className="d-none d-sm-block d-lg-none" />
                    to fuel — tiny, daily actions <br className="d-none d-xl-block" />
                    that are fun, flavorful, and make <br className="d-none d-sm-block d-lg-none" />a huge impact on
                    health (deliciously)!
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xl="10">
                  <h5 className="section-heading">Discover how easy it is to:</h5>
                  <div className="text-h4">
                    <ImgBulletCard img={require('static/backgrounds/1141343601.jpg')}>
                      Do a good deed for health
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1241736799.jpg')}>
                      Become a positive <br className="d-none d-sm-block d-lg-none" />
                      influence on loved ones
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1126508104.jpg')}>
                      Reduce animal cruelty
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1278911675.jpg')}>
                      Help a struggling planet
                    </ImgBulletCard>
                  </div>
                  <h5 className="mt-4 text-center">...just by making a few easy choices.</h5>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="fda-testimonials-1" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-uppercase text-center">
                    Here’s What <br className="d-none d-sm-block d-md-none" />
                    Food Revolution Network’s Members Say About Adding in More <br className="d-none d-xl-block" />
                    Plant-Based <br className="d-none d-sm-block d-md-none" />
                    Whole Foods:
                  </h2>
                  <p className="text-center text-16 mb-0">
                    <em>
                      These testimonials reflect people’s unique experience.{' '}
                      <br className="d-none d-sm-block d-md-none" />
                      Your specific experience will vary.
                    </em>
                  </p>
                  <hr className="my-5" />
                  <TestimonialCircle id="donna-b" />
                  <hr className="my-5" />
                  <TestimonialCircle id="eric-c" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="terri-c" />
                  <hr className="my-5" />
                  <TestimonialCircle id="margaret-s" imgPosition="right" />
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="fda-win"
            color="light-gray"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1339027651.jpg')}
          >
            <h3 className="section-heading">
              These are just a tiny fraction <br className="d-none d-sm-block d-md-none" />
              of <br className="d-none d-xl-block" />
              the phenomenal wins we’ve seen <br className="d-none d-xl-block" />
              when people adopt a whole foods, <br className="d-none d-xl-block" />
              plant-based lifestyle.
            </h3>
            <p>
              For over 10 years, we’ve witnessed it all: <br className="d-none d-lg-block d-xl-none" />
              tears of joy, lives renewed, relationships saved, <br className="d-none d-lg-block d-xl-none" />
              doctors astonished, total health turnarounds, <br className="d-none d-lg-block d-xl-none" />
              revived hope, new leases on life…
            </p>
            <p>
              Inside the <b>14-Day Plant-Powered Accelerator,</b> we <br className="d-none d-lg-block d-xl-none" />
              help people become energy-filled, health-conscious, compassionate, enthusiastic natural foods eaters —
              capable of outshining their former selves and shattering their old ideas of what it means to get older.
            </p>
            <p>
              What’s the deal? Why is the <b>14-Day Plant-Powered Accelerator</b> <br className="d-none d-xl-block" />
              so outstanding for people looking to jumpstart an entirely new <br className="d-none d-xl-block" />
              way of life?
            </p>
            <p>
              Because it’s so{' '}
              <b>
                <i>simple.</i>
              </b>
            </p>
          </SectionImage>

          <Section id="fda-intro" color="white">
            <Container>
              <Row className="justify-content-center">
                <Col lg="10" className="text-center">
                  <p className="mb-2">INTRODUCING THE</p>
                  <img
                    className="mb-3"
                    src="https://cdn.foodrevolution.org/fda/fda-logo.svg"
                    style={{maxHeight: '100px'}}
                  />
                  <p>
                    <i>Fast Track Your Plant-Powered Health</i>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="fda-secrets"
            color="white"
            imgPosition="right"
            imgUrl={require('static/backgrounds/1210530744.jpg')}
            title={
              <h2 className="section-only-heading text-center">
                With the 14-Day Plant-Powered Accelerator, <br className="d-none d-xl-block" />
                get another healthy eating secret delivered <br className="d-none d-xl-block" />
                straight <br className="d-none d-sm-block d-lg-none" />
                to your inbox every day!
              </h2>
            }
            titleClass="mb-3"
          >
            <IconList>
              <IconListItem>
                <b>Breathe new life</b>{' '}
                {variant === 'ad' ? 'into previously mundane kitchen routines' : 'into your kitchen routine'}.
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Skyrocket know-how — and <b>confidence</b> that eating{' '}
                    <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    healthier IS possible.
                  </>
                ) : (
                  <>
                    Skyrocket your know-how — and your <b>confidence</b>{' '}
                    <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    that you CAN eat healthier.
                  </>
                )}
              </IconListItem>
              <IconListItem>
                Cut through the nutrition confusion and get the{' '}
                <br className="d-none d-sm-block d-md-none d-xl-block" />
                <b>evidence-based facts.</b>
              </IconListItem>
              <IconListItem>
                <b>Delight {variant === 'ad' ? null : 'your '}family and friends</b> with new recipes.{' '}
                <br className="d-none d-sm-block d-lg-none d-xl-block" />
                (They’ll beg {variant === 'ad' ? null : 'you '}for a copy!)
              </IconListItem>
              <IconListItem>
                Amp up {variant === 'ad' ? null : 'your '}
                <b>motivation</b> by discovering WHY <br className="d-none d-sm-block d-lg-none d-xl-block" />
                certain foods are {variant === 'ad' ? 'optimal for health' : 'good for you'}.
              </IconListItem>
              <IconListItem>
                Cruise through the week with <b>more free time</b> <br className="d-none d-sm-block" />— and more energy
                — than ever.
              </IconListItem>
              <IconListItem>
                <b>Cut out food waste</b> (and that sad feeling of <br className="d-none d-sm-block d-md-none" />
                watching <br className="d-none d-md-block d-lg-none d-xl-block" />
                food go in the garbage).
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Feel good knowing how to eat in ways <br className="d-none d-sm-block d-md-none" />
                    that <br className="d-none d-lg-block" />
                    help <b>climate change.</b>
                  </>
                ) : (
                  <>
                    Feel good, knowing you’re doing your part to{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />
                    <b>reduce climate change.</b>
                  </>
                )}
              </IconListItem>
              {variant === 'ad' ? null : (
                <IconListItem>
                  <b>Fool-proof your kitchen</b> and launch <br className="d-none d-lg-block d-xl-none" />
                  your new healthy lifestyle.
                </IconListItem>
              )}
              <IconListItem>
                Enjoy the short-term and long-term benefits of the planet’s most <b>nutrient-dense superfoods,</b> which
                are readily available and often <br className="d-none d-md-block d-lg-none" />
                less expensive than prepared meals!
              </IconListItem>
            </IconList>
          </SectionImage>

          <Section id="fda-cta-1" color="gradient-indigo-purple-right">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10" xl="8">
                  <CtaSalesBox />
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4 className="text-white text-center mt-5">
                    This is {variant === 'ad' ? 'the best' : 'your'} chance to get the{' '}
                    <br className="d-none d-sm-block d-md-none" />
                    most cutting-edge <br className="d-none d-lg-block" />
                    health information <br className="d-none d-sm-block d-md-none" />
                    <i>
                      pulled directly from scientific <br className="d-none d-lg-block" />
                      and medical studies
                    </i>{' '}
                    (NOT opinion or fads).
                  </h4>
                  <p className="text-white text-center">
                    <i>
                      Some of this information is so new, <br className="d-none d-sm-block d-md-none" />
                      {variant === 'ad' ? 'many doctors don’t' : 'your doctor might not'} know about it yet.
                    </i>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="fda-modules" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center">
                    {variant === 'ad' ? (
                      <>
                        Here’s some of <br className="d-none d-sm-block d-md-none" />
                        what’s inside the <br className="d-none d-md-block" />
                        14-Day <br className="d-none d-sm-block d-md-none" />
                        Plant-Powered Accelerator:
                      </>
                    ) : (
                      <>
                        Here’s some of what <br className="d-none d-sm-block d-lg-none" />
                        you’ll discover in the <br className="d-none d-lg-block" />
                        14-Day <br className="d-none d-sm-block d-lg-none" />
                        Plant-Powered Accelerator:
                      </>
                    )}
                  </h2>
                  {variant === 'ad' ? <ModuleList list={modulesAd} /> : <ModuleList />}
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="fda-guarantee" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <GuaranteeBox title={<>A Total 100%-Money-Back Guarantee</>}>
                    <p>
                      We’re sure you’re going to love the 14-Day Plant-Powered Accelerator,{' '}
                      <br className="d-none d-md-block d-lg-none" />
                      and that you’ll be excited to pass <br className="d-none d-xl-block" />
                      this exciting wisdom <br className="d-none d-md-block d-lg-none" />
                      on to your friends and loved ones.
                    </p>
                    <p>
                      But if for any reason whatsoever you don’t, we’ll give{' '}
                      <br className="d-none d-sm-block d-lg-none" />
                      you every penny back.
                    </p>
                    <p>
                      Plus, for every 14-Day Plant-Powered Accelerator that’s{' '}
                      <br className="d-none d-sm-block d-md-none" />
                      sold, we’ll donate to Trees for the Future so they <br className="d-none d-xl-block" />
                      can plant an organic fruit or <br className="d-none d-md-block d-lg-none" />
                      nut tree in a low-income community. Our mission at Food Revolution Network{' '}
                      <br className="d-none d-xl-block" />
                      is healthy, ethical, sustainable food for all. We care deeply about our planet and donate many
                      thousands of dollars a year to causes that make the world{' '}
                      <br className="d-none d-sm-block d-md-none" />a better place.
                    </p>
                    <p>
                      We believe that a healthier world starts when one person at a time makes a small but significant{' '}
                      <br className="d-none d-xl-block" />
                      shift in the right direction.
                    </p>
                    <p>It’s never too early (or too late) to start!</p>
                  </GuaranteeBox>
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="fda-express"
            color="white"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1289797791.jpg')}
          >
            <h2 className="section-heading">
              The 14-Day Plant-Powered Accelerator is the “express lane” <br className="d-none d-sm-block d-lg-none" />
              to a new and healthier life.
            </h2>
            <p>
              We can’t wait to hear about the results and wins <br className="d-none d-sm-block d-md-none d-lg-block" />
              you’ll get in just 2 weeks.
            </p>
            <p>
              So, if you want those results, jump on this <br className="d-none d-lg-block d-xl-none" />
              special offer now.
            </p>
            {!salesFullPrice ? (
              <>
                <p>
                  It’s listed on our website for $47, but now’s your chance to{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  get it for less than a fancy green juice at the airport.
                </p>
                <p>
                  We’re not sure how long we’ll be able to keep offering{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  the 14-Day Plant-Powered Accelerator for just $7.
                </p>
                <p>(It’s a whopping 85% off, after all.)</p>
                <p>That’s the lowest price you’ll ever see.</p>
              </>
            ) : null}
            <p>Just click the button and you’re instantly in.</p>
          </SectionImage>

          <Section id="fda-cta-3" className="section-angle-last" color="gradient-indigo-purple-right" angle="none">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10" xl="8">
                  <CtaSalesBox />
                </Col>
              </Row>
            </Container>
          </Section>
        </>
      ) : null}

      {pageLength === 'versionTwo' ? (
        <>
          <SectionImage
            id="fda-reading"
            color="white"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1136640820.jpg')}
          >
            <h3 className="section-heading">Keep reading {variant === 'ad' ? null : 'if you want'} to:</h3>
            <IconList>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Become <b>healthier</b> — no pricey <br className="d-none d-lg-block d-xl-none" />
                    supplements required.
                  </>
                ) : (
                  <>
                    Become a <b>healthier person</b> — no pricey supplements required.
                  </>
                )}
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    <b>Have more energy,</b> in a 100% natural way that takes as little as{' '}
                    <br className="d-none d-md-block d-lg-none" />
                    five minutes a day. (Many people in their 100s swear by this!)
                  </>
                ) : (
                  <>
                    <b>Instantly expand</b> your energy, in a 100% natural way{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />
                    that takes as little as five minutes a day. (Many people{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />
                    in their 100s swear by this!)
                  </>
                )}{' '}
              </IconListItem>
              <IconListItem>
                <b>Avoid</b> unnecessary medications, doctors’ <br className="d-none d-lg-block d-xl-none" />
                visits, and surgeries that <br className="d-none d-md-block d-lg-none" />
                take weeks or months <br className="d-none d-lg-block d-xl-none" />
                to recover from.
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    <b>Easily</b> make the switch from energy-draining foods to vibrant,{' '}
                    <br className="d-none d-sm-block d-lg-none" />
                    life-giving foods.
                  </>
                ) : (
                  <>
                    <b>Effortlessly</b> switch from life-zapping foods <br className="d-none d-lg-block d-xl-none" />
                    to vibrant, <br className="d-none d-sm-block d-md-none d-xl-block" />
                    life-giving foods.
                  </>
                )}
              </IconListItem>
              <IconListItem>
                {variant === 'ad' ? (
                  <>
                    Adopt simple <b>motivation methods</b> to pick <br className="d-none d-lg-block d-xl-none" />
                    healthy options <br className="d-none d-sm-block d-lg-none d-xl-block" />— even when hungry or{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    at a party or picnic.
                  </>
                ) : (
                  <>
                    Adopt iron-clad <b>motivation methods</b> so you pick healthy options{' '}
                    <br className="d-none d-md-block d-lg-none" />
                    no matter where you are or how hungry you are{' '}
                    <br className="d-none d-sm-block d-md-none d-xl-block" />— even at{' '}
                    <br className="d-none d-md-block d-lg-none" />a party or picnic.
                  </>
                )}
              </IconListItem>
            </IconList>
          </SectionImage>

          <Section id="fda-necessity" color="light-gray">
            <Container>
              <Row className="justify-content-center">
                <Col xl="11" className="text-center">
                  <h3 className="section-heading">We created this course out of necessity.</h3>
                  <p>
                    After years of watching well-intentioned people attempt to completely overhaul{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    their diets only to crash <br className="d-none d-xl-block" />a few weeks later, we realized that
                    small steps taken in <br className="d-none d-lg-block d-xl-none" />
                    the right way are what lead to LASTING change.
                  </p>
                  <p>
                    That’s exactly what the 14-Day Plant-Powered Accelerator is designed{' '}
                    <br className="d-none d-sm-block d-lg-none" />
                    to fuel — tiny, daily actions <br className="d-none d-xl-block" />
                    that are fun, flavorful, and make <br className="d-none d-sm-block d-lg-none" />a huge impact on
                    health (deliciously)!
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xl="10">
                  <h5 className="section-heading">Discover how easy it is to:</h5>
                  <div className="text-h4">
                    <ImgBulletCard img={require('static/backgrounds/1141343601.jpg')}>
                      Do a good deed for health
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1241736799.jpg')}>
                      Become a positive <br className="d-none d-sm-block d-lg-none" />
                      influence on loved ones
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1126508104.jpg')}>
                      Reduce animal cruelty
                    </ImgBulletCard>
                    <ImgBulletCard img={require('static/backgrounds/1278911675.jpg')}>
                      Help a struggling planet
                    </ImgBulletCard>
                  </div>
                  <h5 className="mt-4 text-center">...just by making a few easy choices.</h5>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="fda-testimonials-1" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-uppercase text-center">
                    Here’s What <br className="d-none d-sm-block d-md-none" />
                    Food Revolution Network’s Members Say About Adding in More <br className="d-none d-xl-block" />
                    Plant-Based <br className="d-none d-sm-block d-md-none" />
                    Whole Foods:
                  </h2>
                  <p className="text-center text-16 mb-0">
                    <em>
                      These testimonials reflect people’s unique experience.{' '}
                      <br className="d-none d-sm-block d-md-none" />
                      Your specific experience will vary.
                    </em>
                  </p>
                  <hr className="my-5" />
                  <TestimonialCircle id="donna-b" />
                  <hr className="my-5" />
                  <TestimonialCircle id="eric-c" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="terri-c" />
                  <hr className="my-5" />
                  <TestimonialCircle id="margaret-s" imgPosition="right" />
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="fda-win"
            color="light-gray"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1339027651.jpg')}
          >
            <h3 className="section-heading">
              These are just a tiny fraction <br className="d-none d-sm-block d-md-none" />
              of <br className="d-none d-xl-block" />
              the phenomenal wins we’ve seen <br className="d-none d-xl-block" />
              when people adopt a whole foods, <br className="d-none d-xl-block" />
              plant-based lifestyle.
            </h3>
            <p>
              For over 10 years, we’ve witnessed it all: <br className="d-none d-lg-block d-xl-none" />
              tears of joy, lives renewed, relationships saved, <br className="d-none d-lg-block d-xl-none" />
              doctors astonished, total health turnarounds, <br className="d-none d-lg-block d-xl-none" />
              revived hope, new leases on life…
            </p>
            <p>
              Inside the <b>14-Day Plant-Powered Accelerator,</b> we <br className="d-none d-lg-block d-xl-none" />
              help people become energy-filled, health-conscious, compassionate, enthusiastic natural foods eaters —
              capable of outshining their former selves and shattering their old ideas of what it means to get older.
            </p>
            <p>
              What’s the deal? Why is the <b>14-Day Plant-Powered Accelerator</b> <br className="d-none d-xl-block" />
              so outstanding for people looking to jumpstart an entirely new <br className="d-none d-xl-block" />
              way of life?
            </p>
            <p>
              Because it’s so{' '}
              <b>
                <i>simple.</i>
              </b>
            </p>
          </SectionImage>

          <Section id="fda-cta-1" color="gradient-indigo-purple-right">
            <Container>
              <Row className="justify-content-center text-white mb-4">
                <Col lg="10" className="text-center">
                  <p className="mb-2">INTRODUCING THE</p>
                  <img
                    className="mb-3"
                    src="https://cdn.foodrevolution.org/fda/fda-logo-white.svg"
                    style={{maxHeight: '100px'}}
                  />
                  <p>
                    <i>Fast Track Your Plant-Powered Health</i>
                  </p>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col lg="10" xl="8">
                  <CtaSalesBox />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="text-white text-center mt-5">
                    This is {variant === 'ad' ? 'the best' : 'your'} chance to get the{' '}
                    <br className="d-none d-sm-block d-md-none" />
                    most cutting-edge <br className="d-none d-lg-block" />
                    health information <br className="d-none d-sm-block d-md-none" />
                    <i>
                      pulled directly from scientific <br className="d-none d-lg-block" />
                      and medical studies
                    </i>{' '}
                    (NOT opinion or fads).
                  </h4>
                  <p className="text-white text-center">
                    <i>
                      Some of this information is so new, <br className="d-none d-sm-block d-md-none" />
                      {variant === 'ad' ? 'many doctors don’t' : 'your doctor might not'} know about it yet.
                    </i>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="fda-guarantee" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <GuaranteeBox title={<>A Total 100%-Money-Back Guarantee</>}>
                    <p>
                      We’re sure you’re going to love the 14-Day Plant-Powered Accelerator,{' '}
                      <br className="d-none d-md-block d-lg-none" />
                      and that you’ll be excited to pass <br className="d-none d-xl-block" />
                      this exciting wisdom <br className="d-none d-md-block d-lg-none" />
                      on to your friends and loved ones.
                    </p>
                    <p>
                      But if for any reason whatsoever you don’t, we’ll give{' '}
                      <br className="d-none d-sm-block d-lg-none" />
                      you every penny back.
                    </p>
                    <p>
                      Plus, for every 14-Day Plant-Powered Accelerator that’s{' '}
                      <br className="d-none d-sm-block d-md-none" />
                      sold, we’ll donate to Trees for the Future so they <br className="d-none d-xl-block" />
                      can plant an organic fruit or <br className="d-none d-md-block d-lg-none" />
                      nut tree in a low-income community. Our mission at Food Revolution Network{' '}
                      <br className="d-none d-xl-block" />
                      is healthy, ethical, sustainable food for all. We care deeply about our planet and donate many
                      thousands of dollars a year to causes that make the world{' '}
                      <br className="d-none d-sm-block d-md-none" />a better place.
                    </p>
                    <p>
                      We believe that a healthier world starts when one person at a time makes a small but significant{' '}
                      <br className="d-none d-xl-block" />
                      shift in the right direction.
                    </p>
                    <p>It’s never too early (or too late) to start!</p>
                  </GuaranteeBox>
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="fda-express"
            color="white"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1289797791.jpg')}
          >
            <h2 className="section-heading">
              The 14-Day Plant-Powered Accelerator is the “express lane” <br className="d-none d-sm-block d-lg-none" />
              to a new and healthier life.
            </h2>
            <p>
              We can’t wait to hear about the results and wins <br className="d-none d-sm-block d-md-none d-lg-block" />
              you’ll get in just 2 weeks.
            </p>
            <p>
              So, if you want those results, jump on this <br className="d-none d-lg-block d-xl-none" />
              special offer now.
            </p>
            {!salesFullPrice ? (
              <>
                <p>
                  It’s listed on our website for $47, but now’s your chance to{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  get it for less than a fancy green juice at the airport.
                </p>
                <p>
                  We’re not sure how long we’ll be able to keep offering{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  the 14-Day Plant-Powered Accelerator for just $7.
                </p>
                <p>(It’s a whopping 85% off, after all.)</p>
                <p>That’s the lowest price you’ll ever see.</p>
              </>
            ) : null}
            <p>Just click the button and you’re instantly in.</p>
          </SectionImage>

          <Section id="fda-cta-3" className="section-angle-last" color="gradient-indigo-purple-right" angle="none">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10" xl="8">
                  <CtaSalesBox />
                </Col>
              </Row>
            </Container>
          </Section>
        </>
      ) : null}
    </>
  );
}
