import React, {Component, useCallback, useEffect} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import PageMeta from 'components/PageMeta';
import Video from 'components/Video';
import CheckoutButton from 'components/CheckoutButton';
import Footer from 'components/Elements/Footer';
import logoTrees from 'static/logo/logo-trees-for-future-white.png';
import wlcLogo from 'static/logo/frn-wlc-logo-white.svg';

import Section, {SectionImage} from 'components/Elements/Section';
import useBeacon from 'utils/useBeacon';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {WlcUpsellMonth} from 'components/SalesCta';
import {TestimonialCircle} from 'components/Testimonial';
import {ImgBulletCard} from 'components/Elements/ImgBullet';

export default function () {
  useBeacon();
  return (
    <div className="page page-nosocial">
      <PageMeta title="WLC Upsell" description="Make FOOD the foundation of YOUR health" />

      <div className="page-content">
        <Section
          id="section-wlc-header"
          className="section-angle-first"
          innerClass="text-white text-center"
          color="black"
        >
          <Container>
            <Row className="d-flex justify-content-center mb-4">
              <Col>
                <p>
                  <i>Exciting things are on the way!</i>
                  <br />
                  Your first 14-Day Plant-Powered Accelerator email <br className="d-none d-sm-block d-lg-none" />
                  will reach your inbox in a few minutes.
                </p>
                <h3 className="mt-3 mb-0">
                  Before you go, do you want a free <br className="d-none d-sm-block d-xl-none" />
                  month in WHOLE Life Club?
                </h3>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <div className="px-3 px-sm-0">
                  <h5 className="section-heading text-uppercase mt-3">
                    Watch This Important Message <br className="d-none d-sm-block d-lg-none" />
                    From Ocean Robbins:
                  </h5>
                </div>
                <Video url="https://www.youtube.com/watch?v=jRYQPDRNVPc" label="FDA - WLC Upsell" />
              </Col>
            </Row>

            <Row className="text-center mt-5">
              <Col>
                <p>
                  The 14-Day Plant-Powered Accelerator kicks off your healthy eating journey.{' '}
                  <br className="d-none d-lg-block" />
                  But make sure you don’t slip after 2 weeks…
                </p>
                <p>
                  <b>
                    WHOLE Life Club is the #1 way to make healthy habits stick, <i>for LIFE.</i>
                  </b>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="section-wlc-offer-1" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <h5 className="section-only-heading text-center text-white">
                  A free 30 day membership to <br className="d-none d-sm-block d-lg-none" />
                  WHOLE Life Club is waiting for you.
                </h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col md="10" lg="8" xl="6">
                <img src={wlcLogo} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center text-white">
                <h5 className="mt-0">Just click “Add to Order” and it’s yours.</h5>
                <p>
                  <em>This offer is ONLY available from this page so please don’t hit refresh.</em>
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <WlcUpsellMonth noTrees noDisclaimer />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="section-wlc-secret" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-3">
                  Wondering how people <br className="d-none d-md-block d-lg-none" />
                  in their 50s, 60s, <br className="d-none d-xl-block" />
                  and even 90s stay energetic and youthful?
                </h2>
                <h3 className="text-center mt-0 mb-5">
                  <em>
                    Are they just lucky? <br className="d-none d-sm-block d-md-none" />
                    What’s their secret?
                  </em>
                </h3>
                <TestimonialCircle id="wlc-kathy-w-short" imgPosition="right" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-christopher" className="mb-5" />
                <p className="text-center text-16 mb-0">
                  <em>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. <br className="d-none d-xl-block" />
                    Their testimonials reflect their unique experience. Your specific results will vary.
                  </em>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="section-wlc-answers"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/825154854.jpg')}
        >
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">
                  Do you have a place where you can go to get answers to all your health questions — even the ones you
                  didn’t know you had?
                </h3>
                <p>
                  If you’ve ever tried to find answers on YouTube or random blogs, then you know how frustrating it can
                  be to read 10 articles that all seem to contradict each other.
                </p>
                <p>
                  <strong>
                    That’s why we created a way for you to get ALL your questions answered by our Food Revolution
                    Network team, which includes registered dietitians and certified health coaches.
                  </strong>
                </p>
                <p>
                  Our team has encountered just about everything, and will give you feedback and insights based on the
                  latest scientific studies and research for just about any health issue that pops up.
                </p>
                <p>This kind of help can be a true lifesaver.</p>
              </Col>
            </Row>
          </Container>
        </SectionImage>

        <Section id="section-wlc-join-title" color="green" innerClass="text-white text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-only-heading">
                  Here’s what you’ll get <br className="d-none d-lg-block" />
                  when you join WHOLE Life Club
                </h2>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="section-wlc-join-1"
          imgPosition="right"
          color="white"
          imgUrl={require('static/backgrounds/couple-computer-square.jpg')}
        >
          <h3 className="section-heading">Guidance</h3>
          <IconList>
            <IconListItem>
              <strong>Ocean’s Action of the Week videos with Action Checklists:</strong> Clear guidance to help you make
              steady progress towards your health goals every single week.
            </IconListItem>
            <IconListItem>
              <strong>Monthly WHOLE Life Action Hours:</strong> Explore key health issues with world-renowned experts —
              from immune strength to hormone balance, anti-cancer insights, and more. Submit your questions ahead of
              time and get answers that could change your life.
            </IconListItem>
            <IconListItem>
              <strong>Direction and feedback from our dieticians and coaches.</strong> Get support, address health
              issues as they arise, and get answers for you and your family.
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="section-wlc-join-2"
          imgPosition="left"
          color="light-gray"
          imgUrl={require('static/backgrounds/shiitake-bacon-tacos-2.jpg')}
        >
          <h3 className="section-heading">Resources and Recipes</h3>
          <IconList>
            <IconListItem>
              <strong>
                5 new whole-foods, plant-powered recipes every week (and a library of hundreds of other recipes).
              </strong>{' '}
              Eat your way to fantastic health with these nutritious, simple, and affordable recipes that you and your
              family will love.
            </IconListItem>
            <IconListItem>
              <strong>Tools and resources you need</strong> to seamlessly build healthy habits into your life.
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="section-wlc-join-3"
          imgPosition="right"
          color="white"
          imgUrl={require('static/backgrounds/1201069511.jpg')}
        >
          <h3 className="section-heading">Community Support</h3>
          <IconList>
            <IconListItem>
              <strong>Join a vibrant, supportive community</strong> to share insights, find accountability partners,
              celebrate wins, and overcome obstacles — together.
            </IconListItem>
            <IconListItem>
              <strong>Use the moderated forum</strong> (on our custom platform — 100% Facebook-free) to start and join
              in conversations on topics that matter to you. Finally, a warm place to connect with peers who share your
              values and concerns!
            </IconListItem>
          </IconList>
        </SectionImage>

        <Section id="section-wlc-join-4" color="white" innerClass="text-center">
          <Container>
            <Row>
              <Col>
                <p>
                  <strong>
                    <em>
                      You’ll also get access to our entire library including hundreds of whole food recipes, inspiring
                      Action of the Week videos, and informative WHOLE Life Action Hour videos — all yours instantly.
                    </em>
                  </strong>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="section-wlc-imagine" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center mb-3">Put today’s best health insights into ACTION.</h3>
                <h3 className="text-center mt-0 mb-3">Build healthy habits that LAST.</h3>
                <h3 className="text-center mt-0">Nurture LIFELONG HEALTH AND VITALITY.</h3>
                <h4 className="mb-4">Imagine…</h4>
                <ImgBulletCard img={require('static/backgrounds/1312604977.jpg')}>
                  Knowing where to get guidance from dieticians, health coaches, and leading health experts...
                </ImgBulletCard>
                <ImgBulletCard img={require('static/backgrounds/1256836165.jpg')}>
                  Knowing that you have <em>exactly</em> the recipes and resources you need to hit your health goals, no
                  matter where you’re at in your journey...
                </ImgBulletCard>
                <ImgBulletCard img={require('static/backgrounds/1076518194.jpg')}>
                  A loving community of friends and allies by your side with accountability and support every step of
                  the way...
                </ImgBulletCard>
                <h2 className="text-center mt-4 mb-0">WHOLE Life Club is where it happens.</h2>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="section-wlc-testimonials" color="white">
          <Container>
            <Row>
              <Col>
                <TestimonialCircle id="wlc-cindy" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-robin-long" imgPosition="right" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-genevieve-d-alt" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          id="section-wlc-offer-2"
          className="section-angle-last"
          color="gradient-indigo-purple-right"
          angle="none"
        >
          <Container>
            <Row>
              <Col>
                <WlcUpsellMonth noTrees noDisclaimer />
              </Col>
            </Row>
          </Container>
        </Section>
      </div>

      <Footer />
    </div>
  );
}
